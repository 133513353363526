/* eslint-disable react/no-array-index-key */
/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-empty */
/* eslint-disable unicorn/empty-brace-spaces */
/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-extra-parens */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */

// @flow
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable jsx-a11y/no-autofocus */
import { toastError, toastInfo, toastLoading, toastSuccess, toastUpdate, toastWarning } from "@3edges/utils/dist/toastify";
import { decryptText, encryptText, formatDate, generateRandomString, isEmpty, isNotEmpty, isValidUrl, isWebLocalhost, sleepFor } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem, Tab, Tabs } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { ExpandMore } from '@material-ui/icons';
import { COLORS } from "assets/defaultVariables";
import { getStorage } from "cache";
import
    {
        ADD_NEW_PRIM_DATA_SERVER,
        ADD_NEW_PRIM_TENANT_DATA_SERVER, CHECK_CONNECTION_URL, CHECK_DATABASE_CONNECTION, CHECK_DNS, CHECK_URL_HEALTH, DELETE_SCHEMA_DB, LOAD_SCHEMA_DB, LOAD_SCHEMA_VP, UPDATE_PRIM_DATA_SERVER, UPDATE_THE_SCHEMA
    } from "components/ModalDataServer/gql";
import { AlertDSName, ButtonListFlex, ConfirmButton, TextAreaStyled } from "components/ModalDataServer/styled";
import ModalCredentialsInfo from "components/PanelServer/ModalCredentialsInfo";
import { ALL_STATUS } from "components/PanelServer/gql";
import { GET_MODAL_INFO } from "components/PrimGraphicalCanvas/gql";
import { SelectFieldStyled, SwitchBoxStyled, SwitchButton, SwitchLabelStyled } from "components/RightPanel/styled";
import { UsersContainer } from "components/UsersItem/styled";
import { useData } from "contexts/dataContext";
import { REACT_ENV } from "environmentVariables";
import { GraphQLError } from "graphql";
import { Form } from "pages/CreateNewUser/styled";
import { GET_CLUSTERS_BY_SERVICE_ACCOUNT, GET_SERVICE_ACCOUNTS_BY_USER } from "pages/ServiceAccounts/gql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AccessTokenType, IExternalProvider, OIDCProviderEnum } from "typedefs";
import { ClusterEnum, GetAllDataServers_niamDataServers_authZServer, OperatorEnum, getModalInfo_getModalInfo, podEnum } from "types/operation-result-types";
import { ProcessingApiServerIconStyled } from "ui-components/PanelCard/styled";
import { Modal } from "ui-components/modal";
import { PwdField } from "ui-components/pwdField";
import { TextField } from "ui-components/styleds";
import { UrlField } from "ui-components/urlField";
import { isAdminOnThisDataserver, isUrlHttpsValid, setNameLowerCase } from "utils";
import { Cluster, isAdmin, isSuperAdmin } from "utilsFn";
import { InfoCell, ItemFlexContainer, RowContainer, StatusLightBulb, URLBox, ValueCell } from "./styledSup";

interface ModalDsProps {
    isModalOpen: boolean;
    apiServerID: any;
    onClose: (dataSaved?: any, closeModal?: boolean) => void;
    refetchPrimInfo?: () => void;
    tenantID?: string;
}

function ModalDS ({
    isModalOpen,
    apiServerID,
    onClose,
    refetchPrimInfo,
    tenantID,
}: ModalDsProps): React.ReactElement {
    const { t } = useTranslation();
    const currUser = getStorage<any>("user");
    const { getDataServer } = useData();

    const showSaveButton = isAdmin() || isSuperAdmin()
    const [saving, setSaving] = useState(true);
    const [name, setName] = useState("");
    const [lastUpdate, setLastUpdate] = useState("Unknown");
    const [randomName, setRandomName] = useState("");
    const [description, setDescription] = useState("");
    const [connectionURL, setConnectionURL] = useState("");
    const [editDataServer, setEditDataServer] = useState<getModalInfo_getModalInfo | null>(null);

    const [getApiServerDetails, { loading, data }] = useLazyQuery<getModalInfo_getModalInfo>(GET_MODAL_INFO, {
        fetchPolicy: "no-cache"
    });

    const [getAllStatus, { data: dataAllStatus, loading: allStatusLoading, refetch: refetchService }] = useLazyQuery(ALL_STATUS, { fetchPolicy: "no-cache" });

    const [MAX_TROTTLE_COST, setMAX_TROTTLE_COST] = useState("");
    const [NEO4J_POOL_SIZE, setNEO4J_POOL_SIZE] = useState("");
    const [NEO4J_MAX_CONNECTION_LIFETIME, setNEO4J_MAX_CONNECTION_LIFETIME] = useState("");
    const [NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS, setNEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS] = useState("");
    const [NEO4J_CONNECTION_TIMEOUT, setNEO4J_CONNECTION_TIMEOUT] = useState("");

    const [dbType, setDBType] = useState("");
    const [dbVersion, setDBVersion] = useState("");
    const [dbHost, setDBHost] = useState("");
    const [dbName, setDBName] = useState("");
    const [dbUser, setDBUser] = useState("");
    const [dbPass, setDBPass] = useState("");

    const [loadSchemaDB, { loading: loadSchemaDBLoading }] = useLazyQuery(LOAD_SCHEMA_DB, { fetchPolicy: "no-cache" });
    const [loadSchemaVP, { loading: loadingSchemaVPLoading }] = useMutation(LOAD_SCHEMA_VP);
    const [nodeProps, setNodeProps] = useState(false);
    const [relProps, setRelProps] = useState(false);
    const [VPString, setVPString] = useState("");

    const [authZDefaultOperator, setAuthZDefaultOperator] = useState(OperatorEnum.OR);
    const [authZAllowByDefault, setAuthZAllowByDefault] = useState(false);
    const [authZIsMostPermissive, setAuthZIsMostPermissive] = useState(true);
    const [actionPermissionOrder, setActionPermissionOrder] = useState("Read,Create,Update,Delete,CRUD,Custom");
    const [authZSelfRelDepth, setAuthZSelfRelDepth] = useState(3);
    const [authZConnectionURL, setAuthZConnectionURL] = useState("");

    const [authzCSPConnectionURL, setAuthzCSPConnectionURL] = useState("");
    const [dashboardServerURL, setDashboardServerURL] = useState("");
    const [expanded, setExpanded] = React.useState(false);

    const [OIDC_PROVIDER, setOIDCProvider] = useState(OIDCProviderEnum.THREEEDGES.toString());
    const [OIDC_URL, setOIDCURL] = useState("");
    const [CLAIMS_ARRAY, setUserClaims] = useState("_id,username,subtype");
    const [AUTHN_QUERY, setAuthenticationQuery] = useState("MATCH (user:$SUBJECT_TYPE) WHERE user.$NAMING_PROPERTY = $username WITH user, '$SUBJECT_TYPE' as subtype RETURN user{ .*, subtype }");
    const [EXTERNAL_OIDC_URL, setExternalOidcUrl] = useState("");
    const [OIDC_TOKEN_ENDPOINT, setTokenEndpoint] = useState("/token");
    const [OIDC_TOKEN_INTROSPECTION_ENDPOINT, setIntrospectionEndpoint] = useState("/token/introspection");
    const [ACCESS_TOKEN_TYPE, setAccessTokenType] = useState(AccessTokenType.opaque.toString());
    const [OIDC_JWKS_URI, setJwksURI] = useState("/jwks");
    const [EXTERNAL_PROVIDER, setEXTERNAL_PROVIDER] = useState(false);
    const [EXTERNAL_PROVIDER_NAME, setExternalProviderName] = useState(IExternalProvider.Other.toString());
    const [OIDC_AUTHORIZE_ENDPOINT, setAuthorizeEndpoint] = useState("/authorize");
    const [OIDC_LOGOUT_ENDPOINT, setLogoutEndpoint] = useState("/revoke");
    const [OIDC_ME_ENDPOINT, setUserInfoEndpoint] = useState("/me");
    const [OIDC_PROVIDER_CLAIMS_MAPPING, setTokenClaimsMapping] = useState('{"_id": "sub", "username": "email"}');
    const [OIDC_CLIENT_ID, setClientID] = useState("");
    const [OIDC_CLIENT_PWD, setClientPassword] = useState("");

    const [selectedTab, setSelectedTab] = useState(0);
    const [updateSchema, { loading: loadingSchema }] = useMutation(UPDATE_THE_SCHEMA);

    const [isProxyRunning, setIsProxyRunning] = useState<boolean | null>(null);
    const [isIdpRunning, setIsIdpRunning] = useState<boolean | null>(null);
    const [isAuthzRunning, setIsAuthzRunning] = useState<boolean | null>(null);
    const [isDashboardRunning, setIsDashboardRunning] = useState<boolean | null>(null);

    const [isMissingDBCredentials, setIsMissingDBCredentials] = useState<boolean | null>(false);
    const [isMissingServiceAccount, setIsMissingServiceAccount] = useState<boolean | null>(isWebLocalhost() ? false : true);

    const [addNewNiamDataServer] = useMutation(ADD_NEW_PRIM_DATA_SERVER);
    const [addNewNiamDataServerByTenant] = useMutation(ADD_NEW_PRIM_TENANT_DATA_SERVER);
    const [updateNiamDataServer] = useMutation(UPDATE_PRIM_DATA_SERVER);
    const [deleteSchemaDB] = useMutation(DELETE_SCHEMA_DB);

    const [authZServer, setAuthZServer] = useState<GetAllDataServers_niamDataServers_authZServer | null>();
    const [CLUSTER_SELECTED, setCLUSTER_SELECTED] = useState<any>();
    const [clusterAutopilot, setClusterAutopilot] = useState<boolean>(false);
    const [clusterLocation, setClusterLocation] = useState<any>();
    const [autoDeployApiServer, setAutoDeployApiServer] = useState<boolean>(true);
    const [serviceAccountID, setServiceAccountID] = useState("");
    const [clusterID, setClusterID] = useState("");

    const [isModalCredentialsInfoOpen, setIsModalCredentialsInfoOpen] = useState({
        isOpen: false,
        dbValid: false
    });

    const [getServiceAccounts, { data: dataSA }] = useLazyQuery(GET_SERVICE_ACCOUNTS_BY_USER, { fetchPolicy: "no-cache" });
    const [getClustersByServiceAccount, { data: dataCluster, loading: loadingClusters }] = useLazyQuery(GET_CLUSTERS_BY_SERVICE_ACCOUNT, { fetchPolicy: "no-cache" });

    const [checkDNS, { loading: loadingDNS }] = useLazyQuery(CHECK_DNS, { fetchPolicy: "no-cache" });
    const [checkConnectionURL, { loading: loadingConnectionURL }] = useLazyQuery(CHECK_CONNECTION_URL, { fetchPolicy: "no-cache" });
    const [checkUrlHealth] = useLazyQuery(CHECK_URL_HEALTH, { fetchPolicy: "no-cache" });
    const [checkDatabaseConnection, { loading: loadingDatabaseConnection }] = useLazyQuery(CHECK_DATABASE_CONNECTION, { fetchPolicy: "no-cache" });
    const [connectionURLAlreadyInUseDS, setConnectionURLAlreadyInUseDS] = useState([]);

    const [dataServiceAccounts, setDataServiceAccounts] = useState([]);
    const [dataClusters, setDataClusters] = useState([]);
    const [loadingApis, setLoadingApis] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [tmpMessage, setTmpMessage] = useState({
        text: "",
        color: ""
    });

    const isCurrentAdmin = isAdminOnThisDataserver((editDataServer as any)?.administrators, currUser) || isWebLocalhost()

    const dbTypesAvailables = [
        {
            id: 1,
            context: "neo4j",
            title: "Neo4j",
            versions: [
                { id: 1, context: "v4", title: "4.x.x" },
                { id: 2, context: "v5", title: "5.x.x" }
            ]
        },
        // {
        //     id: 2,
        //     context: "memgraph",
        //     title: "Memgraph",
        //     versions: [
        //         { id: 1, context: "v2", title: "2.x.x" }
        //     ]
        // }
    ]

    const [dbVersionAvailables, setDbVersionAvailables] = useState([]);

    const clusterAvailables = [
        { id: 1, context: Cluster.THREEEDGES, title: "3edges" },
        { id: 2, context: Cluster.NEWCLUSTER, title: "Create a new cluster" }
    ]

    const clusterAutopilotAvailables = [
        { id: 1, context: false, title: "No" },
        { id: 2, context: true, title: "Yes" }
    ]

    const autoDeployApiServerOptions = [
        { id: 1, context: false, title: "No" },
        { id: 2, context: true, title: "Yes" }
    ]

    const clusterLocationAvailables =[
        { id: 1, context: "northamerica-northeast1-a", title: "Canada" },
        { id: 2, context: "us-central1-a", title: "USA" },
        { id: 3, context: "europe-north1-a", title: "Europe" }
    ]

    useEffect(() => {
        if (dataSA && dataSA.getServiceAccountsByUser.length > 0) {
            setDataServiceAccounts(dataSA.getServiceAccountsByUser)
        }
    }, [dataSA]);

    useEffect(() => {
        if (dataCluster && dataCluster.getClustersByServiceAccount.length > 0) {
            setDataClusters(dataCluster.getClustersByServiceAccount)
        } else {
            setDataClusters([])
        }
    }, [dataCluster]);

    useEffect(() => {
        setLoadingApis(loadingDatabaseConnection || loadingDNS || loadingConnectionURL)
    }, [loadingDatabaseConnection, loadingDNS, loadingConnectionURL]);

    useEffect(() => {
        if (dataAllStatus)
        {
            const { getAllStatus } = dataAllStatus
            setLastUpdate(getAllStatus.lastUpdate ? formatDate(getAllStatus.lastUpdate) : t("serverInfo:modal.tabs.update.server.last.unknown"));

            if (!allStatusLoading) {
                if (getAllStatus.clusterStatus === ClusterEnum.PROCESSING || getAllStatus.podStatus === podEnum.PROCESSING) {
                    if (getAllStatus.clusterStatus === ClusterEnum.DISCONNECTED) {
                        setSaving(false)
                    } else {
                        setSaving(CLUSTER_SELECTED !== Cluster.LOCALHOST ? true : false)
                    }
                } else {
                    setSaving(false)
                }
            }
        }
    }, [dataAllStatus, allStatusLoading])

    const resetModal = () => {
        getServiceAccounts({ variables: { serverID: null } })
        const randomNameValue = setNameLowerCase(`api-${generateRandomString()}`)

        setSaving(false);
        setName("");
        setDescription("");
        setTmpMessage({ text: "", color: "" })
        setHasError(false);
        setConnectionURLAlreadyInUseDS([]);
        setCLUSTER_SELECTED(undefined);
        checkDBConnection(clusterAvailables[0].context)
        setAutoDeployApiServer(autoDeployApiServerOptions[1].context);
        setClusterAutopilot(clusterAutopilotAvailables[0].context);
        setClusterLocation(clusterLocationAvailables[0].context);
        setServiceAccountID("");
        setClusterID("");
        setRandomName(randomNameValue)
        setMAX_TROTTLE_COST("")
        setNEO4J_POOL_SIZE("300")
        setNEO4J_MAX_CONNECTION_LIFETIME("3600000")
        setNEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS("60000")
        setNEO4J_CONNECTION_TIMEOUT("30000")
        setAuthZDefaultOperator(OperatorEnum.OR);
        setAuthZSelfRelDepth(3);
        setAuthZAllowByDefault(false);
        setAuthZIsMostPermissive(true);
        setActionPermissionOrder("Read,Create,Update,Delete,CRUD,Custom");
        setSelectedTab(0);
        setOIDCProvider(OIDCProviderEnum.THREEEDGES)
        setEXTERNAL_PROVIDER(false)
        setOIDCURL("");
        setIntrospectionEndpoint("/token/introspection");
        setAccessTokenType(AccessTokenType.opaque);
        setJwksURI("/jwks");
        setExternalProviderName(IExternalProvider.Other);
        setUserInfoEndpoint("/me");
        setAuthorizeEndpoint("/authorize");
        setLogoutEndpoint("/revoke");
        setTokenClaimsMapping('{"_id": "sub", "username": "email"}');
        setClientID("");
        setClientPassword("");
        setConnectionURL("");
        setAuthZConnectionURL("");
        setDashboardServerURL("");
        setAuthzCSPConnectionURL("")
        newConnectionName(randomNameValue)
    }

    useEffect(() => {
        if (isModalOpen) {
            if (apiServerID) {
                getApiServerDetails({ variables: { serverID: apiServerID } })
            } else {
                resetModal()
            }
        }
    }, [isModalOpen, apiServerID])

    useEffect(() => {
        if (data) {
            setEditDataServer(data["getModalInfo"])
        }
    }, [data])

    useEffect(() => {
        if (!isModalOpen) {
            setEditDataServer(null)
        }
    }, [isModalOpen])

    useEffect(() => {
        if (editDataServer) {
            getAllStatus({ variables: { serverID: editDataServer._id } })
            getServiceAccounts({ variables: { serverID: editDataServer?._id } })

            if (editDataServer?.serviceAccount?._id) {
                getClustersByServiceAccount({ variables: { serviceAccountID: editDataServer?.serviceAccount?._id } })
            }

            setSaving(true)

            setName(editDataServer.name);
            setDescription(editDataServer.description);
            setTmpMessage({ text: "", color: "" })
            setCLUSTER_SELECTED(editDataServer.CLUSTER_SELECTED || undefined);
            setClusterAutopilot(editDataServer.clusterAutopilot || clusterAutopilotAvailables[0].context);
            setClusterLocation(editDataServer.clusterLocation || clusterLocationAvailables[0].context)
            setAutoDeployApiServer(editDataServer?.autoDeployApiServer);
            setServiceAccountID(editDataServer?.serviceAccount?._id || "");
            setClusterID(editDataServer?.cluster?._id || "");
            setConnectionURL(editDataServer?.connectionURL || "")
            setAuthZConnectionURL(editDataServer?.authZServer.connectionURL || "")
            setRandomName(editDataServer.clientNameGCP);
            setDashboardServerURL(editDataServer?.dashboardServerURL || "")
            setAuthzCSPConnectionURL(editDataServer?.authzCSPConnectionURL || "")
            setDBType(editDataServer.dbType || "");
            setDBVersion(editDataServer.dbVersion || "");

            if (editDataServer?.serviceAccount?._id && editDataServer?.cluster?._id) {
                setIsMissingServiceAccount(false)
            } else {
                if (!isWebLocalhost()) {
                    setIsMissingServiceAccount(true)
                }
            }

            if (editDataServer.dbType) {
                const currentVersions = dbTypesAvailables.find(t => t.context === editDataServer.dbType)
                setDbVersionAvailables(currentVersions.versions)
            }

            setDBHost(editDataServer.dbHost || "");
            setDBName(editDataServer.dbName || "");
            setDBUser(editDataServer.dbUser || "");
            setDBPass(editDataServer.dbPass ? decryptText(editDataServer.dbPass, REACT_ENV.REACT_APP_INTERNAL_SECRET) : "");
            setMAX_TROTTLE_COST(editDataServer.MAX_TROTTLE_COST)
            setNEO4J_POOL_SIZE(editDataServer.NEO4J_POOL_SIZE || "300")
            setNEO4J_MAX_CONNECTION_LIFETIME(editDataServer.NEO4J_MAX_CONNECTION_LIFETIME || "3600000")
            setNEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS(editDataServer.NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS || "60000")
            setNEO4J_CONNECTION_TIMEOUT(editDataServer.NEO4J_CONNECTION_TIMEOUT || "30000")

            if (editDataServer.authZServer) {
                setAuthZServer({ ...editDataServer.authZServer });
            } else {
                setAuthZServer(null)
            }

            if(editDataServer.EXTERNAL_PROVIDER) {
                setOIDCProvider(OIDCProviderEnum.EXTERNAL);
                setEXTERNAL_PROVIDER(true)
            } else {
                setOIDCProvider(OIDCProviderEnum.THREEEDGES);
                setEXTERNAL_PROVIDER(false)
            }

            setOIDCURL(editDataServer?.OIDC_URL || "");

            setUserClaims(editDataServer?.CLAIMS_ARRAY || "_id,username,subtype");
            setAuthenticationQuery(editDataServer?.AUTHN_QUERY || "MATCH (user:$SUBJECT_TYPE) WHERE user.$NAMING_PROPERTY = $username WITH user, '$SUBJECT_TYPE' as subtype RETURN user{ .*, subtype }")
            setExternalOidcUrl(editDataServer?.EXTERNAL_OIDC_URL || "");
            setIntrospectionEndpoint(editDataServer?.OIDC_TOKEN_INTROSPECTION_ENDPOINT || "/token/introspection");
            setTokenEndpoint(editDataServer?.OIDC_TOKEN_ENDPOINT || "/token");
            setAccessTokenType(AccessTokenType[editDataServer?.ACCESS_TOKEN_TYPE] || AccessTokenType.opaque);
            setJwksURI(editDataServer?.OIDC_JWKS_URI || "/jwks");
            setExternalProviderName(editDataServer?.EXTERNAL_PROVIDER_NAME || IExternalProvider.Other);
            setUserInfoEndpoint(editDataServer?.OIDC_ME_ENDPOINT || "/me");
            setAuthorizeEndpoint(editDataServer?.OIDC_AUTHORIZE_ENDPOINT || "/authorize");
            setLogoutEndpoint(editDataServer?.OIDC_LOGOUT_ENDPOINT || "/revoke");
            setTokenClaimsMapping(editDataServer?.OIDC_PROVIDER_CLAIMS_MAPPING || '{"_id": "sub", "username": "email"}');
            setClientID(editDataServer?.OIDC_CLIENT_ID || "");
            setClientPassword(editDataServer?.OIDC_CLIENT_PWD ? decryptText(editDataServer?.OIDC_CLIENT_PWD, REACT_ENV.REACT_APP_INTERNAL_SECRET) : "");

            if (!editDataServer?.autoDeployApiServer) {
                testConnectionsOnLoad()
            }
        }

    }, [editDataServer]);

    useEffect(() =>
    {
        if (window.location.hostname === Cluster.LOCALHOST)
        {
            setCLUSTER_SELECTED(editDataServer?.CLUSTER_SELECTED || Cluster.LOCALHOST)
        } else if (window.location.hostname === REACT_ENV.REACT_APP_DOMAIN) {
            setCLUSTER_SELECTED(Cluster.QA)
        } else {
            if (REACT_ENV.REACT_APP_ENABLE_NEWCLUSTER === "true") {
                setCLUSTER_SELECTED(undefined)
            } else {
                setCLUSTER_SELECTED(clusterAvailables[0].context)
            }

            setClusterAutopilot(clusterAutopilotAvailables[0].context)
            setClusterLocation(clusterLocationAvailables[0].context)
        }
    }, [CLUSTER_SELECTED]);

    useEffect(() => {
        if (authZServer)
        {
            setAuthZDefaultOperator(authZServer.defaultOperator || OperatorEnum.OR);
            setAuthZAllowByDefault(authZServer.allowByDefault || false);
            setAuthZIsMostPermissive(authZServer.isMostPermissive || false);
            setActionPermissionOrder(authZServer.actionPermissionOrder || "Read,Create,Update,Delete,CRUD,Custom");
            setAuthZSelfRelDepth(authZServer.selfRelDepth || 3);
            setAuthZConnectionURL(authZServer.connectionURL || "");
        }
    }, [authZServer]);

    const testConnectionsOnLoad = async () =>
    {
        try {
            const dataProxy = await checkUrlHealth({ variables: { url: editDataServer?.connectionURL } })
            setIsProxyRunning(dataProxy.data.checkUrlHealth.status)
        } catch {
            setIsProxyRunning(false)
        }

        try {
            const dataAuthz = await checkUrlHealth({ variables: { url: editDataServer?.authZServer?.connectionURL } })
            setIsAuthzRunning(dataAuthz.data.checkUrlHealth.status)
        } catch {
            setIsAuthzRunning(false)
        }

        try
        {
            const dataIdp = await checkUrlHealth({ variables: { url: editDataServer?.EXTERNAL_PROVIDER ? editDataServer?.EXTERNAL_OIDC_URL : editDataServer?.OIDC_URL } })
            setIsIdpRunning(dataIdp.data.checkUrlHealth.status)
        } catch {
            setIsIdpRunning(false)
        }

        try {
            const dataDashboard = await checkUrlHealth({ variables: { url: editDataServer?.dashboardServerURL } })
            setIsDashboardRunning(dataDashboard.data.checkUrlHealth.status)
        } catch {
            setIsDashboardRunning(false)
        }
    }

    const checkDBConnection = (currentCluster) =>
    {
        if (isSuperAdmin())
        {
            if (currentCluster === Cluster.LOCALHOST) {
                const currentType = editDataServer?.CLUSTER_SELECTED === currentCluster ? editDataServer?.dbType : REACT_ENV.REACT_APP_LOCALHOST_DB_TYPE
                setDBType(currentType)

                const currentVersions = dbTypesAvailables.find(t => t.context === currentType)
                setDbVersionAvailables(currentVersions.versions)
                setDBVersion(editDataServer?.CLUSTER_SELECTED === currentCluster ? editDataServer?.dbVersion : REACT_ENV.REACT_APP_LOCALHOST_DB_VERSION)

                setDBHost(editDataServer?.CLUSTER_SELECTED === currentCluster ? editDataServer?.dbHost : REACT_ENV.REACT_APP_LOCALHOST_DB_HOST)
                setDBName(editDataServer?.CLUSTER_SELECTED === currentCluster ? editDataServer?.dbName : REACT_ENV.REACT_APP_LOCALHOST_DB_NAME)
                setDBUser(editDataServer?.CLUSTER_SELECTED === currentCluster ? editDataServer?.dbUser : REACT_ENV.REACT_APP_LOCALHOST_DB_USER)
                setDBPass(editDataServer?.CLUSTER_SELECTED === currentCluster ? decryptText(editDataServer?.dbPass, REACT_ENV.REACT_APP_INTERNAL_SECRET) : REACT_ENV.REACT_APP_LOCALHOST_DB_PASSWORD)
            } else {
                setDBType("")
                setDBVersion("")
                setDBHost("")
                setDBName("")
                setDBUser("")
                setDBPass("")
            }
        } else {
            if (!editDataServer) {
                setDBType("")
                setDBVersion("")
                setDBHost("")
                setDBName("")
                setDBUser("")
                setDBPass("")
            }
        }
    }

    const newConnectionName = (randomNameValue) =>
    {
        switch (CLUSTER_SELECTED)
        {
            case Cluster.QA:
            case Cluster.THREEEDGES:
                setConnectionURL(editDataServer?.connectionURL || REACT_ENV.REACT_APP_3EDGES_PROXY.replace("tmp-random-url", randomNameValue))
                setAuthZConnectionURL(editDataServer?.authZServer?.connectionURL || REACT_ENV.REACT_APP_3EDGES_PROXY.replace("tmp-random-url", `${randomNameValue}-authz`))
                setAuthzCSPConnectionURL(editDataServer?.authzCSPConnectionURL || REACT_ENV.REACT_APP_3EDGES_PROXY.replace("tmp-random-url", `${randomNameValue}-authz-csp`))
                setDashboardServerURL(editDataServer?.dashboardServerURL || REACT_ENV.REACT_APP_3EDGES_PROXY.replace("tmp-random-url", `${randomNameValue}-dashboard`))
                setOIDCURL(editDataServer?.OIDC_URL || REACT_ENV.REACT_APP_3EDGES_IDP.replace("tmp-random-url", `${randomNameValue}-idp`))
                setExternalOidcUrl(editDataServer?.OIDC_URL || REACT_ENV.REACT_APP_3EDGES_IDP.replace("tmp-random-url", `${randomNameValue}-idp`))
                break;
            case Cluster.NEWCLUSTER:
                setConnectionURL(editDataServer?.connectionURL || REACT_ENV.REACT_APP_NEWCLUSTER_PROXY.replace("tmp-random-url", randomNameValue))
                setAuthZConnectionURL(editDataServer?.authZServer?.connectionURL || REACT_ENV.REACT_APP_NEWCLUSTER_PROXY.replace("tmp-random-url", `${randomNameValue}-authz`))
                setAuthzCSPConnectionURL(editDataServer?.authzCSPConnectionURL || REACT_ENV.REACT_APP_NEWCLUSTER_PROXY.replace("tmp-random-url", `${randomNameValue}-authz-csp`))
                setDashboardServerURL(editDataServer?.dashboardServerURL || REACT_ENV.REACT_APP_NEWCLUSTER_PROXY.replace("tmp-random-url", `${randomNameValue}-dashboard`))
                setOIDCURL(editDataServer?.OIDC_URL || REACT_ENV.REACT_APP_NEWCLUSTER_IDP.replace("tmp-random-url", `${randomNameValue}-idp`))
                setExternalOidcUrl(editDataServer?.OIDC_URL || REACT_ENV.REACT_APP_NEWCLUSTER_IDP.replace("tmp-random-url", `${randomNameValue}-idp`))
                break;
            default:
                setConnectionURL(REACT_ENV.REACT_APP_LOCALHOST_PROXY)
                setAuthZConnectionURL(REACT_ENV.REACT_APP_LOCALHOST_AUTHZ)
                setAuthzCSPConnectionURL(REACT_ENV.REACT_APP_LOCALHOST_AUTHZ_CSP)
                setDashboardServerURL(REACT_ENV.REACT_APP_LOCALHOST_DASHBOARD)
                setOIDCURL(REACT_ENV.REACT_APP_LOCALHOST_IDP_PROXY)
                setExternalOidcUrl(REACT_ENV.REACT_APP_LOCALHOST_IDP_PROXY)
        }

        checkDBConnection(CLUSTER_SELECTED)
    }

    const hasPrefixChanged = (value: string) => {
        value = value.toLowerCase()

        if (!isValidUrl(value)) {
            return
        }

        if (CLUSTER_SELECTED !== Cluster.LOCALHOST) {
            try {
                const url = new URL(value);
                const domain = url.hostname;

                const domainSplitted = domain.split('.');
                const authz_url = value.replace(domainSplitted[0], `${domainSplitted[0]}-authz`);
                const authz_csp_url = value.replace(domainSplitted[0], `${domainSplitted[0]}-authz-csp`);
                const dash_url = value.replace(domainSplitted[0], `${domainSplitted[0]}-dashboard`);
                const oidc_url_value = value.replace(domainSplitted[0], `${domainSplitted[0]}-idp`) + "/oidc";

                setOIDCURL(oidc_url_value)
                setExternalOidcUrl(oidc_url_value)
                setAuthZConnectionURL(authz_url)
                setAuthzCSPConnectionURL(authz_csp_url)
                setDashboardServerURL(dash_url)
            } catch { }
        }
    }

    const showErrors = (errors: readonly GraphQLError[]): void => {
        for (const e of errors) {
            toastError(t(`validations:${e.message}`));
        }

        return;
    };

    const saveDS = async (): Promise<any> =>
    {
        const oidcParams = OIDC_PROVIDER === OIDCProviderEnum.THREEEDGES ? {
            EXTERNAL_PROVIDER,
            OIDC_URL,
            OIDC_JWKS_URI,
            CLAIMS_ARRAY,
            AUTHN_QUERY,
            ACCESS_TOKEN_TYPE
        } : {
            EXTERNAL_PROVIDER,
            EXTERNAL_OIDC_URL,
            OIDC_TOKEN_ENDPOINT,
            OIDC_TOKEN_INTROSPECTION_ENDPOINT,
            OIDC_ME_ENDPOINT,
            OIDC_AUTHORIZE_ENDPOINT,
            OIDC_LOGOUT_ENDPOINT,
            ACCESS_TOKEN_TYPE,
            OIDC_JWKS_URI,
            EXTERNAL_PROVIDER_NAME,
            OIDC_PROVIDER_CLAIMS_MAPPING,
            OIDC_CLIENT_ID,
            OIDC_CLIENT_PWD
        };

        const params = {
            name,
            description,
            connectionURL,
            randomName,
            CLUSTER_SELECTED,
            autoDeployApiServer,
            clusterAutopilot,
            clusterLocation,
            serviceAccountID,
            clusterID,
            AUTHN_QUERY,
            CLAIMS_ARRAY,
            dbParams: {
                dbType,
                dbVersion,
                dbHost,
                dbName,
                dbUser,
                dbPass,
                NEO4J_POOL_SIZE,
                NEO4J_MAX_CONNECTION_LIFETIME,
                NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS,
                NEO4J_CONNECTION_TIMEOUT
            },
            authzParams: {
                connectionURL: authZConnectionURL,
                defaultOperator: authZDefaultOperator,
                allowByDefault: authZAllowByDefault,
                isMostPermissive: authZIsMostPermissive,
                actionPermissionOrder: actionPermissionOrder,
                selfRelDepth: authZSelfRelDepth
            },
            authzCSPConnectionURL,
            dashboardParams: {
                connectionURL: dashboardServerURL
            },
            oidcParams: oidcParams,
            lastReleaseDate: new Date().toISOString()
        }

        let res: any;

        // eslint-disable-next-line unicorn/prefer-ternary
        if (tenantID) {
            res = await addNewNiamDataServerByTenant({ variables: { ...params, tenantID } })
        } else {
            res = await addNewNiamDataServer({ variables: { ...params } })
        }

        if (res.errors) {
            setSaving(false)
            showErrors(res.errors);
            return [null, false]
        }

        return [res.data?.addNewNiamDataServer || res.data?.addNewNiamTenantDataServer, true]
    }

    const editDS = async (removeOldCluster = false): Promise<any> => {

        if (isNotEmpty(editDataServer.dbHost) && isNotEmpty(editDataServer.dbName) && isNotEmpty(editDataServer.dbUser) && isNotEmpty(editDataServer.dbPass)) {
            if (editDataServer.dbHost !== dbHost || editDataServer.dbName !== dbName || editDataServer.dbType !== dbType){
                toastWarning (t("serverInfo:modal.tabs.update.server.database.warning"));
            }
        }

        editDataServer.dbType = dbType;
        editDataServer.dbVersion = dbVersion;
        editDataServer.dbHost = dbHost;
        editDataServer.dbName = dbName;
        editDataServer.dbUser = dbUser;
        editDataServer.dbPass = dbPass ? encryptText(dbPass, REACT_ENV.REACT_APP_INTERNAL_SECRET) : "";

        const oidcParams = OIDC_PROVIDER === OIDCProviderEnum.THREEEDGES ? {
                EXTERNAL_PROVIDER,
                OIDC_URL,
                OIDC_JWKS_URI,
                CLAIMS_ARRAY,
                AUTHN_QUERY,
                ACCESS_TOKEN_TYPE
            } : {
                EXTERNAL_PROVIDER,
                EXTERNAL_OIDC_URL,
                OIDC_TOKEN_ENDPOINT,
                OIDC_TOKEN_INTROSPECTION_ENDPOINT,
                OIDC_ME_ENDPOINT,
                OIDC_AUTHORIZE_ENDPOINT,
                OIDC_LOGOUT_ENDPOINT,
                ACCESS_TOKEN_TYPE,
                OIDC_JWKS_URI,
                EXTERNAL_PROVIDER_NAME,
                OIDC_PROVIDER_CLAIMS_MAPPING,
                OIDC_CLIENT_ID,
                OIDC_CLIENT_PWD
            };

        const res: any = await updateNiamDataServer({
            variables: {
                serverID: editDataServer._id,
                name,
                description,
                connectionURL,
                randomName,
                MAX_TROTTLE_COST,
                CLUSTER_SELECTED,
                clusterAutopilot,
                clusterLocation,
                serviceAccountID,
                clusterID,
                removeOldCluster,
                AUTHN_QUERY,
                CLAIMS_ARRAY,
                tenantID,
                dbParams: {
                    dbType,
                    dbVersion,
                    dbHost,
                    dbName,
                    dbUser,
                    dbPass,
                    NEO4J_POOL_SIZE,
                    NEO4J_MAX_CONNECTION_LIFETIME,
                    NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS,
                    NEO4J_CONNECTION_TIMEOUT
                },
                authzParams: {
                    _id: authZServer ? authZServer._id : null,
                    defaultOperator: authZDefaultOperator,
                    selfRelDepth: authZSelfRelDepth,
                    connectionURL: authZConnectionURL,
                    allowByDefault: authZAllowByDefault,
                    isMostPermissive: authZIsMostPermissive,
                    actionPermissionOrder: actionPermissionOrder
                },
                authzCSPConnectionURL,
                dashboardParams: {
                    connectionURL: dashboardServerURL
                },
                oidcParams: oidcParams,
                lastReleaseDate: new Date().toISOString()
            }
        })

        if (res.errors) {
            setSaving(false)
            showErrors(res.errors);
            return [null, false]
        }

        setAuthZServer({ ...res.data.updateNiamDataServer.authZServer });
        return [res.data?.updateNiamDataServer, false]
    }

    const onSuccess = (dataSaved = null, closeModal = false) => {
        setExpanded(false)
        toastSuccess(t("serverInfo:modal.tabs.update.server.saved.success"));

        if (CLUSTER_SELECTED === "newCluster") {
            toastSuccess(t("serverInfo:modal.tabs.update.server.saved.take-some-minutes"));
        }

        if (dataSaved) {
            onClose(dataSaved, closeModal)

            if (editDataServer) {
                getAllStatus({ variables: { serverID: editDataServer?._id } })
            }
        }
    }

    const onConfirm = async () => {
        setSaving(true)

        if (autoDeployApiServer && !isValidUrl(connectionURL))
        {
            setSaving(false)
            toastWarning(t("serverInfo:apiServer.invalid.connectionURL"));
            return;
        }

        if (!autoDeployApiServer && (connectionURL.includes(`.${REACT_ENV.REACT_APP_DOMAIN}`) || (EXTERNAL_PROVIDER ? EXTERNAL_OIDC_URL : OIDC_URL).includes(`.${REACT_ENV.REACT_APP_DOMAIN}`) || authZConnectionURL.includes(`.${REACT_ENV.REACT_APP_DOMAIN}`) || dashboardServerURL.includes(`.${REACT_ENV.REACT_APP_DOMAIN}`))) {
            setSaving(false)
            toastError(t("serverInfo:apiServer.invalid.autoDeployApiServer.internal"));
            return;
        }

        if (!connectionURL.includes("localhost")) {
            if (!isUrlHttpsValid(connectionURL) || !isUrlHttpsValid(EXTERNAL_PROVIDER ? EXTERNAL_OIDC_URL : OIDC_URL) || !isUrlHttpsValid(authZConnectionURL) || !isUrlHttpsValid(dashboardServerURL)) {
                toastWarning(t("serverInfo:apiServer.invalid.https"));
            }
        }

        if (!autoDeployApiServer && (!isProxyRunning || !isAuthzRunning || !isIdpRunning || !isDashboardRunning)) {
            toastWarning(t("serverInfo:apiServer.invalid.address"));
        }

        if (CLUSTER_SELECTED === Cluster.THREEEDGES || CLUSTER_SELECTED === Cluster.NEWCLUSTER)
        {
            if ((editDataServer && editDataServer?.CLUSTER_SELECTED && editDataServer?.CLUSTER_SELECTED !== CLUSTER_SELECTED) ||
                (CLUSTER_SELECTED === "newCluster" && (editDataServer?.clusterAutopilot !== clusterAutopilot || editDataServer?.clusterLocation !== clusterLocation)))
            {
                // 3edges => new cluster
                // new cluster => 3edges
                const { isConfirmed } = await Swal.fire({
                    icon: "error",
                    title: t("dataserver:try.to.delete.cluster"),
                    showConfirmButton: true,
                    confirmButtonText: t("dataserver:try.to.delete.yes"),
                    showCancelButton: true,
                    cancelButtonText: t("dataserver:try.to.delete.no"),
                    confirmButtonColor: COLORS.GREEN,
                    cancelButtonColor: COLORS.RED
                })

                if (isConfirmed) {
                    const [dataSaved, closeModal] = await editDS(true);
                    if (isNotEmpty(dataSaved)) { onSuccess(dataSaved, closeModal) }
                    updateCurrentData(dataSaved)
                } else {
                    setSaving(false)
                }
            } else {
                const [dataSaved, closeModal] = await (editDataServer ? editDS() : saveDS());
                if (editDataServer) {
                    updateCurrentData(dataSaved)
                }
                if (isNotEmpty(dataSaved)) { onSuccess(dataSaved, closeModal) }
            }
        } else {
            const [dataSaved, closeModal] = await (editDataServer ? editDS() : saveDS());
            if (editDataServer) {
                updateCurrentData(dataSaved)
            }
            if (isNotEmpty(dataSaved)) { onSuccess(dataSaved, closeModal) }
        }
    };

    const updateCurrentData = (dataSaved: any): void => {
        if (dataSaved) {
            setEditDataServer({ ...dataSaved })
        }
    };

    const onChangeSwitch = (checked: boolean): void => {
        setAuthZAllowByDefault(checked);
    };

    const onChangePermissive = (checked: boolean): void => {
        setAuthZIsMostPermissive(checked);
    };

    const openCredentials = async () =>
    {
        const isValid = await testConnection(false)

        setIsModalCredentialsInfoOpen({
            isOpen: true,
            dbValid: isValid
        })
    }

    const deleteCurrentSchema = async () => {
        const { isConfirmed } = await Swal.fire({
            icon: "error",
            title: t("dataserver:ModalDataServer.confirm-delete-schema-database"),
            showConfirmButton: true,
            confirmButtonText: t("dataserver:try.to.delete.yes"),
            showCancelButton: true,
            cancelButtonText: t("dataserver:try.to.delete.no"),
            confirmButtonColor: COLORS.GREEN,
            cancelButtonColor: COLORS.RED
        })

        if (isConfirmed) {
            await deleteSchemaDB({ variables: { serverID: editDataServer?._id } })
            toastSuccess(t("root:btn.done"));

            if (refetchPrimInfo) {
                getDataServer(editDataServer?._id)
            }
        }
    }

    const startLoadingSchemaDB = async () =>
    {
        if (editDataServer)
        {
            if (editDataServer?.dbType !== dbType) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbVersion !== dbVersion) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbHost !== dbHost) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbName !== dbName) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbUser !== dbUser) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (decryptText(editDataServer.dbPass, REACT_ENV.REACT_APP_INTERNAL_SECRET) !== dbPass) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
        }

        const isValid = await testConnection(false)

        if (isValid) {
            const toastID = toastLoading()
            const loadSchemaDBResponse = await loadSchemaDB({ variables: { serverID: editDataServer?._id, withProperties: nodeProps, withRelProperties: relProps } })

            if (!loadSchemaDBResponse.loading) {
                const { errors }: any = loadSchemaDBResponse

                if (errors) {
                    for (const e of errors) {
                        toastUpdate(toastID, t(`validations:${e.message}`), "error")
                    }

                    return;
                }
            }

            if (refetchPrimInfo) {
                refetchPrimInfo();
            }

            toastUpdate(toastID, t("panel:import.successImport"), "success")
        }
    }


    const importSchemaFromVerifiablePresentation = async () =>
    {
        if (editDataServer)
        {
            if (editDataServer?.dbType !== dbType) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbVersion !== dbVersion) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbHost !== dbHost) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbName !== dbName) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (editDataServer?.dbUser !== dbUser) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
            if (decryptText(editDataServer.dbPass, REACT_ENV.REACT_APP_INTERNAL_SECRET) !== dbPass) {
                toastWarning(t("panel:import.differentDB"));
                return
            }
        }

        const isValid = await testConnection(false)

        if (isValid)
        {
            let VPStringTrim = "";

            if(isNotEmpty(VPString)){
                VPStringTrim = VPString.trim();
            }

            const loadSchemaVPResponse = await loadSchemaVP({ variables: { serverID: editDataServer?._id, VP: VPStringTrim } })

            if ( !loadSchemaVPResponse["data"] || !loadSchemaVPResponse["data"]["importConfigVC"]) {
                const { errors }: any = loadSchemaVPResponse
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                } else if(loadSchemaVPResponse["data"]["importConfigVC"] === false){
                    toastError(t(`validations: Could not create schema`));
                    return;
                }
            }


            if (refetchPrimInfo) {
                getDataServer(editDataServer?._id)
            }

            toastSuccess(t("panel:import.successImport"));
        }
    }

    const testConnection = async (showMsg = false) => {
        if (isNotEmpty(dbType) && isNotEmpty(dbVersion) && isNotEmpty(dbHost) && isNotEmpty(dbName) && isNotEmpty(dbUser) && isNotEmpty(dbPass))
        {
            const res: any = await checkDatabaseConnection({
                variables: {
                    dbParams: {
                        dbType,
                        dbVersion,
                        dbHost,
                        dbName,
                        dbUser,
                        dbPass
                    }
                }
            })

            if (res.errors)
            {
                showMsg && showErrors(res.errors);
                return false
            } else {
                showMsg && toastSuccess(t("dataserver:ModalDataServer.checkDatabaseConnection.success"))
                return true
            }
        }

        return false
    }

    const onBlurConnectionURL = async (e) => {
        setTmpMessage({ text: "", color: "" })
        setHasError(false);
        setSaving(false)

        const value = e.target?.value?.toLowerCase()

        if (isNotEmpty(value)) {
            if (!isValidUrl(value)) {
                setTmpMessage({
                    text: t("dataserver:connectionURL.invalid"),
                    color: "darkred"
                })
                return
            }

            if (value[value.length - 1] === "/") {
                setConnectionURL(value.slice(0, -1));
            } else {
                setConnectionURL(value);
            }

            if (editDataServer?._id && value === editDataServer?.connectionURL) {
                setTmpMessage({ text: "", color: "" })
                setHasError(false);
                setSaving(false)
                return
            }

            if (CLUSTER_SELECTED !== Cluster.LOCALHOST) {
                const resCheckConnectionURL = await checkConnectionURL({ variables: { connectionURL: value, serverID: editDataServer?._id } })

                if (!resCheckConnectionURL.loading)
                {
                    const { data, errors }: any = resCheckConnectionURL

                    if (errors) {
                        setTmpMessage({
                            text: t(`validations:${errors[0].message}`),
                            color: 'darkred'
                        })
                        return
                    }

                    if (data) {
                        const { dataservers } = data.checkConnectionURL

                        if (dataservers) {
                            setConnectionURLAlreadyInUseDS(dataservers.filter(ds => ds._id !== editDataServer?._id));
                        }
                    }
                }
            }

            if (autoDeployApiServer && isNotEmpty(value) && !value.includes("localhost") && CLUSTER_SELECTED !== Cluster.LOCALHOST) {
                if (value.includes(`.${REACT_ENV.REACT_APP_DOMAIN}`)) {
                    const resCheckDNS = await checkDNS({ variables: { connectionURL: value } })

                    if (!resCheckDNS.loading && resCheckDNS.data) {
                        const { status } = resCheckDNS.data.checkDNS

                        setTmpMessage({
                            text: status ? t("dataserver:connectionURL.dns.3edges.already.exists") : t("dataserver:connectionURL.dns.3edges.not.exists"),
                            color: status ? 'darkred' : 'darkgreen'
                        })

                        setHasError(!status);
                    }
                }
            } else {
                setTmpMessage({ text: "", color: "" })
            }
        }

        setHasError(false);
        setSaving(false)
    }

    if (loading) {
        return null
    }

    return (
        <Modal
            dataCy="modal_btnClose"
            isOpen={isModalOpen}
            onClose={() => onClose()}
            className="modalDS"
            style={{ width: "720px" }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1>{t("dataserver:ModalDataServer.title")}</h1>

                <AlertDSName $color="gray" $noMargin>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                        <div>API Service ID: {randomName}</div>

                        <div>{`${t("serverInfo:modal.tabs.update.server.last")}: ${lastUpdate}`}</div>
                    </div>
                </AlertDSName>
            </div>

            <Tabs
                value={selectedTab}
                TabIndicatorProps={{ children: <span /> }}
                onChange={async (e, newValue: number) => {
                    setSelectedTab(newValue);

                    if (newValue === 4) {
                        testConnectionsOnLoad()

                        if (autoDeployApiServer) {
                            const testConn = await testConnection()

                            if (!testConn) {
                                setIsMissingDBCredentials(true);
                                toastWarning(`${t(`validations:needs_to_have_database_connection`)}`);
                            } else {
                                setIsMissingDBCredentials(false);
                            }
                        }
                    }
                }}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab value={0} label={t("serverInfo:modal.tabs.general")} />

                <Tab value={1} label={t("serverInfo:modal.tabs.authentication")} />

                <Tab value={2} label={t("serverInfo:modal.tabs.auth")} style={{ display: isEmpty(editDataServer) ? "none" : "block" }} />

                <Tab value={3} label={t("serverInfo:modal.tabs.database")} style={{ display: isEmpty(editDataServer) ? "none" : "block" }} />

                <Tab value={4} label={t("serverInfo:modal.tabs.update.server")} style={{ display: isEmpty(editDataServer) ? "none" : "block" }} />
            </Tabs>

            {selectedTab === 0 && (
            <>
                <TextField
                    label={t("dataserver:ModalDataServer.name.label")}
                    autoFocus
                    data-cy="modalAPIServerPage_name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />

                <TextField
                    label={t("dataserver:ModalDataServer.description.label")}
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                />

                {!isWebLocalhost() && (
                    <>
                        <SelectFieldStyled
                            disabled={editDataServer && isNotEmpty(editDataServer.serviceAccount?._id)}
                            value={serviceAccountID}
                            label={t("serviceaccounts:chooseServiceAccount.label")}
                            onChange={(event) => {
                                setClusterID("");
                                setIsMissingServiceAccount(true)
                                setServiceAccountID(event.target.value);
                                getClustersByServiceAccount({ variables: { serviceAccountID: event.target.value } })
                            }}
                        >
                            {dataServiceAccounts.map((item: any) => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.cloud} {currUser.email !== item.owner ? `- ${item.owner}` : null}
                                </MenuItem>
                            ))}
                        </SelectFieldStyled>

                        <SelectFieldStyled
                            disabled={editDataServer && isNotEmpty(editDataServer.cluster?._id)}
                            value={clusterID}
                            label={loadingClusters ? "Loading..." : t("serviceaccounts:chooseCluster.label")}
                            onChange={(event) => {
                                setClusterID(event.target.value);
                                setIsMissingServiceAccount(false)
                            }}
                        >
                            {dataClusters.map((item: any) => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.name} ({item.location})
                                </MenuItem>
                            ))}
                        </SelectFieldStyled>

                        {!editDataServer && (
                            <SelectFieldStyled
                                value={autoDeployApiServer}
                                label={t("dataserver:chooseAutoDeployApiServer.label")}
                                onChange={(event) =>
                                {
                                    const context = event.target.value === "true";
                                    setAutoDeployApiServer(context);

                                    if (!context) {
                                        setConnectionURL("")
                                        setAuthZConnectionURL("")
                                        setAuthzCSPConnectionURL("")
                                        setOIDCURL("")
                                        setExternalOidcUrl("")
                                        setDashboardServerURL("")
                                    } else {
                                        newConnectionName(randomName)

                                        setTmpMessage({ text: "", color: "" })
                                        setHasError(false);
                                        setSaving(false)
                                    }
                                }}>
                                {autoDeployApiServerOptions.map(({ context, title }) => (
                                    <MenuItem key={context.toString()} value={context.toString()}>
                                        {title}
                                    </MenuItem>
                                ))}
                            </SelectFieldStyled>
                        )}
                    </>
                )}

                {(window.location.hostname === REACT_ENV.REACT_APP_DOMAIN && REACT_ENV.REACT_APP_ENABLE_NEWCLUSTER === "true" && autoDeployApiServer) &&
                    <SelectFieldStyled
                        data-cy="modalAPIServerPage_CLUSTER_SELECTED"
                        value={CLUSTER_SELECTED || ""}
                        label={t("dataserver:chooseCluster.label")}
                        onChange={(event) =>
                        {
                            const context = event.target.value;
                            setCLUSTER_SELECTED(context)
                        }}>
                        {clusterAvailables.map(({ context, title }) => (
                            <MenuItem key={context} value={context}>
                                {title}
                            </MenuItem>
                            ))}
                    </SelectFieldStyled>
                }

                {autoDeployApiServer && (
                    <>
                        <TextField
                            data-cy="modalAPIServerPage_connectionURL"
                            label={t("dataserver:ModalDataServer.connectionURL.label")}
                            placeholder={t("dataserver:ModalDataServer.connectionURL.placeholder")}
                            value={connectionURL}
                            onChange={(e) =>
                            {
                                setConnectionURL(e.target.value);
                                hasPrefixChanged(e.target.value);
                            }}
                            onFocus={() => { setHasError(true); }}
                            onBlur={onBlurConnectionURL}
                        />

                        {loadingApis && (
                            <AlertDSName>{t("dataserver:connectionURL.loading.dns")}</AlertDSName>
                        )}

                        {!loadingApis && isNotEmpty(tmpMessage.text) && (
                            <AlertDSName $color={tmpMessage.color}>{tmpMessage.text}</AlertDSName>
                        )}

                        {!loadingApis && hasError && connectionURLAlreadyInUseDS.length > 0 && (
                            <AlertDSName $color="darkred">{t('dataserver:connectionURL.same.address').replace("$1", `${connectionURLAlreadyInUseDS.map(ds => ds.name)}`)}</AlertDSName>
                        )}
                    </>
                )}

                {editDataServer && (
                    <Accordion style={{ marginTop: 5 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                {t("dataserver:ModalDataServer.advanced-properties.title")}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component="div">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t("dataserver:ModalDataServer.query-complexity.label")}
                                        value={MAX_TROTTLE_COST}
                                        inputProps={{ type: 'number', min: 1 }}
                                        onChange={(e) =>
                                        {
                                            setMAX_TROTTLE_COST(e.target.value);
                                        }}
                                    />
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}

                {!autoDeployApiServer && (
                    <>
                        <UrlField
                            isValid={isProxyRunning}
                            label={t("dataserver:ModalDataServer.connectionURL.label")}
                            value={connectionURL}
                            onChange={(e) => {
                                setConnectionURL(e.target.value);
                            }}
                            onBlur={async (e) => {
                                const value = e.target?.value.toLowerCase()

                                if (isNotEmpty(value)) {
                                    if (value[value.length - 1] === "/") {
                                        setConnectionURL(value.slice(0, -1));
                                    } else {
                                        setConnectionURL(value);
                                    }

                                    try {
                                        const dataProxy = await checkUrlHealth({ variables: { url: value } })
                                        setIsProxyRunning(dataProxy.data.checkUrlHealth.status)
                                    } catch {
                                        setIsProxyRunning(false)
                                    }
                                }
                            }}
                        />

                        <UrlField
                            isValid={isAuthzRunning}
                            label={t("dataserver:ModalDataServer.authZConnectionURL.label")}
                            value={authZConnectionURL}
                            onChange={(e) => {
                                setAuthZConnectionURL(e.target.value);
                            }}
                            onBlur={async (e) => {
                                const value = e.target?.value.toLowerCase()

                                if (isNotEmpty(value)) {
                                    setAuthZConnectionURL(value);

                                    if (value[value.length - 1] === "/") {
                                        setAuthZConnectionURL(value.slice(0, -1));
                                    }

                                    try {
                                        const dataAuthz = await checkUrlHealth({ variables: { url: value } })
                                        setIsAuthzRunning(dataAuthz.data.checkUrlHealth.status)
                                    } catch {
                                        setIsAuthzRunning(false)
                                    }
                                }
                            }}
                        />

                        <UrlField
                            isValid={isIdpRunning}
                            label={t("dataserver:ModalDataServer.oidc.oidcURL")}
                            value={OIDC_URL}
                            onChange={(e) => {
                                setOIDCURL(e.target.value);

                                if (!autoDeployApiServer) {
                                    setExternalOidcUrl(e.target.value);
                                }
                            }}
                            onBlur={async (e) => {
                                const value = e.target?.value.toLowerCase()

                                if (isNotEmpty(value)) {
                                    if (value[value.length - 1] === "/") {
                                        setOIDCURL(value.slice(0, -1));

                                        if (!autoDeployApiServer) {
                                            setExternalOidcUrl(value.slice(0, -1));
                                        }
                                    } else {
                                        setOIDCURL(value);

                                        if (!autoDeployApiServer) {
                                            setExternalOidcUrl(value);
                                        }
                                    }

                                    try {
                                        const dataIdp = await checkUrlHealth({ variables: { url: value } })
                                        setIsIdpRunning(dataIdp.data.checkUrlHealth.status)
                                    } catch {
                                        setIsIdpRunning(false)
                                    }
                                }
                            }}
                        />

                        <UrlField
                            isValid={isDashboardRunning}
                            label={t("dataserver:ModalDataServer.dashboardServerURL.label")}
                            value={dashboardServerURL}
                            onChange={(e) => {
                                setDashboardServerURL(e.target.value);
                            }}
                            onBlur={async (e) => {
                                const value = e.target?.value.toLowerCase()

                                if (isNotEmpty(value)) {
                                    if (value[value.length - 1] === "/") {
                                        setDashboardServerURL(value.slice(0, -1));
                                    } else {
                                        setDashboardServerURL(value);
                                    }

                                    try {
                                        const dataDashboard = await checkUrlHealth({ variables: { url: value } })
                                        setIsDashboardRunning(dataDashboard.data.checkUrlHealth.status)
                                    } catch {
                                        setIsDashboardRunning(false)
                                    }
                                }
                            }}
                        />
                    </>
                )}

                {(name.length > 0 && CLUSTER_SELECTED === "newCluster") && CLUSTER_SELECTED &&
                    <SelectFieldStyled
                        value={clusterAutopilot}
                        label={t("dataserver:chooseClusterAutopilot.label")}
                        onChange={(event) => {
                            const context = event.target.value === "true";
                            setClusterAutopilot(context);
                        }}>
                        {clusterAutopilotAvailables.map(({ context, title }) => (
                            <MenuItem key={context.toString()} value={context.toString()}>
                                {title}
                            </MenuItem>
                        ))}
                    </SelectFieldStyled>
                }

                {(name.length > 0 && CLUSTER_SELECTED === "newCluster") && CLUSTER_SELECTED &&
                    <SelectFieldStyled
                        value={clusterLocation || ""}
                        label={t("dataserver:chooseClusterLocation.label")}
                        onChange={(event) =>
                        {
                            const context = event.target.value;
                            setClusterLocation(context)
                        }}>
                            {clusterLocationAvailables.map(({ context, title }) => (
                                <MenuItem key={context} value={context}>
                                    {title}
                                </MenuItem>
                        ))}
                    </SelectFieldStyled>
                }
            </>
            )}

            {selectedTab === 1 && (
                <>
                    <SelectFieldStyled
                        name="oidcProvider"
                        value={OIDC_PROVIDER}
                        label={t("dataserver:ModalDataServer.oidc.oidcProvider")}
                        onChange={(e) => {
                            setExpanded(false)
                            setOIDCProvider(e.target.value)
                            setEXTERNAL_PROVIDER((e.target.value === OIDCProviderEnum.EXTERNAL))
                        }}>
                            <MenuItem value={OIDCProviderEnum.THREEEDGES}>
                                3Edges
                            </MenuItem>

                            <MenuItem value={OIDCProviderEnum.EXTERNAL}>
                                External
                            </MenuItem>
                    </SelectFieldStyled>

                    { OIDC_PROVIDER === OIDCProviderEnum.THREEEDGES &&  (
                    <Accordion expanded={expanded} onChange={() => { setExpanded(!expanded) }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                Authentication Properties
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component="div">
                                <Form>
                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.oidcURL")}
                                        value={OIDC_URL}
                                        disabled={CLUSTER_SELECTED !== Cluster.LOCALHOST || !autoDeployApiServer}
                                        onChange={(e) => {
                                            setOIDCURL(e.target.value);
                                        }}
                                    />

                                    <TextAreaStyled
                                        label={t("dataserver:ModalDataServer.oidc.userClaims")}
                                        style={{ width: '100%' }}
                                        value={CLAIMS_ARRAY}
                                        onChange={(e) => {
                                            setUserClaims(e.target.value);
                                        }}
                                    />

                                    <SelectFieldStyled
                                        name="accessTokenType"
                                        value={ACCESS_TOKEN_TYPE}
                                        label={t("dataserver:ModalDataServer.oidc.accessTokenType")}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            setAccessTokenType(e.target.value)
                                        }}>
                                        <MenuItem value={AccessTokenType.jwt}>
                                            jwt
                                        </MenuItem>

                                        <MenuItem value={AccessTokenType.opaque}>
                                            opaque
                                        </MenuItem>
                                    </SelectFieldStyled>

                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.jwksURI")}
                                        value={OIDC_JWKS_URI}
                                        onChange={(e) => {
                                            setJwksURI(e.target.value);
                                        }}
                                    />

                                    <TextAreaStyled
                                        label={t("dataserver:ModalDataServer.oidc.authenticationQuery")}
                                        style={{ width: '100%' }}
                                        value={AUTHN_QUERY}
                                        onChange={(e) => {
                                            setAuthenticationQuery(e.target.value);
                                        }}
                                    />

                                </Form>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    )}

                    { OIDC_PROVIDER === OIDCProviderEnum.EXTERNAL &&  (
                    <Accordion expanded={expanded} onChange={() => { setExpanded(!expanded) }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                Authentication Properties
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component="span">
                                <Form>
                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.providerURL")}
                                        value={EXTERNAL_OIDC_URL}
                                            onChange={(e) => {
                                                setExternalOidcUrl(e.target.value);

                                                if (!autoDeployApiServer) {
                                                    setOIDCURL(e.target.value);
                                                }
                                        }}
                                        onBlur={(e) => {
                                            const value = e.target?.value.toLowerCase()

                                            if (value[value.length - 1] === "/") {
                                                setExternalOidcUrl(value.slice(0, -1));
                                                if (!autoDeployApiServer) {
                                                    setOIDCURL(value.slice(0, -1));
                                                }
                                            } else {
                                                setExternalOidcUrl(value);
                                                if (!autoDeployApiServer) {
                                                    setOIDCURL(value);
                                                }
                                            }
                                        }}
                                    />

                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.tokenEndpoint")}
                                        value={OIDC_TOKEN_ENDPOINT}
                                        onChange={(e) => {
                                            setTokenEndpoint(e.target.value);
                                        }}
                                    />

                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.introspectionEndpoint")}
                                        value={OIDC_TOKEN_INTROSPECTION_ENDPOINT}
                                        onChange={(e) => {
                                            setIntrospectionEndpoint(e.target.value);
                                        }}
                                    />

                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.authorizeEndpoint")}
                                        value={OIDC_AUTHORIZE_ENDPOINT}
                                        onChange={(e) => {
                                            setAuthorizeEndpoint(e.target.value);
                                        }}
                                        />

                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.logoutEndpoint")}
                                        value={OIDC_LOGOUT_ENDPOINT}
                                        onChange={(e) => {
                                            setLogoutEndpoint(e.target.value);
                                        }}
                                    />

                                    <AlertDSName $color="gray">{t("dataserver:ModalDataServer.oidc.logoutEndpointDescription")}</AlertDSName>

                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.userinfoEndpoint")}
                                        value={OIDC_ME_ENDPOINT}
                                        onChange={(e) => {
                                            setUserInfoEndpoint(e.target.value);
                                        }}
                                    />

                                    <SelectFieldStyled
                                        name="accessTokenType"
                                        value={ACCESS_TOKEN_TYPE}
                                        label={t("dataserver:ModalDataServer.oidc.accessTokenType")}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            setAccessTokenType(e.target.value)
                                        }}>
                                            <MenuItem value={AccessTokenType.jwt}>
                                                jwt
                                            </MenuItem>

                                            <MenuItem value={AccessTokenType.opaque}>
                                                opaque
                                            </MenuItem>
                                    </SelectFieldStyled>

                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.jwksURI")}
                                        value={OIDC_JWKS_URI}
                                        onChange={(e) => {
                                            setJwksURI(e.target.value);
                                        }}
                                    />

                                    <SelectFieldStyled
                                        value={EXTERNAL_PROVIDER_NAME}
                                        label={t("dataserver:ModalDataServer.oidc.externalProviderName")}
                                        onChange={(e) => {
                                            setExternalProviderName(IExternalProvider[e.target.value])
                                    }}>
                                        <MenuItem value={IExternalProvider.ForgeRock}>
                                            {IExternalProvider.ForgeRock}
                                        </MenuItem>

                                        <MenuItem value={IExternalProvider.Auth0}>
                                            {IExternalProvider.Auth0}
                                        </MenuItem>

                                        <MenuItem value={IExternalProvider.Okta}>
                                            {IExternalProvider.Okta}
                                        </MenuItem>

                                        <MenuItem value={IExternalProvider.Other}>
                                            {IExternalProvider.Other}
                                        </MenuItem>

                                    </SelectFieldStyled>

                                    <TextAreaStyled
                                        label={t("dataserver:ModalDataServer.oidc.tokenClaimsMapping")}
                                        style={{ width: '100%' }}
                                        value={OIDC_PROVIDER_CLAIMS_MAPPING}
                                        onChange={(e) => {
                                            setTokenClaimsMapping(e.target.value);
                                        }}
                                    />


                                    <TextField
                                        label={t("dataserver:ModalDataServer.oidc.clientID")}
                                        value={OIDC_CLIENT_ID}
                                        onChange={(e) => {
                                            setClientID(e.target.value);
                                        }}
                                    />

                                    <PwdField
                                        label={t("dataserver:ModalDataServer.oidc.clientPassword")}
                                        value={OIDC_CLIENT_PWD}
                                        onChange={(e) => {
                                            setClientPassword(e.target.value);
                                        }}
                                    />

                                </Form>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    )}
                </>
            )}

            {selectedTab === 2 && (
                <>
                    {(name.length > 0 || CLUSTER_SELECTED === Cluster.LOCALHOST) && CLUSTER_SELECTED && (
                        <>
                            <TextField
                                data-cy="modalAPIServerPage_authZConnectionURL"
                                disabled={CLUSTER_SELECTED !== Cluster.LOCALHOST || !autoDeployApiServer}
                                label={t("dataserver:ModalDataServer.auth.connectionURL.placeholder")}
                                value={authZConnectionURL}
                                onChange={(e) => {
                                    setAuthZConnectionURL(e.target.value);
                                }}
                            />
                        </>
                    )}

                    <SelectFieldStyled
                        name="defaultOperator"
                        value={authZDefaultOperator || OperatorEnum.OR}
                        label={t("dataserver:ModalDataServer.auth.operator.label")}
                        onChange={(e) => {
                            setAuthZDefaultOperator(OperatorEnum[e.target.value])
                    }}>
                        <MenuItem value={OperatorEnum.OR}>
                            OR
                        </MenuItem>

                        <MenuItem value={OperatorEnum.AND}>
                            AND
                        </MenuItem>
                    </SelectFieldStyled>

                    <TextField
                        label={t("dataserver:ModalDataServer.auth.selfRelDepth.label")}
                        inputProps={{ type: 'number', min: 1, max: 10 }}
                        value={authZSelfRelDepth}
                        onBlur={(e) =>
                        {
                            const v = Number(e.target.value)

                            if (isEmpty(v))
                            {
                                setAuthZSelfRelDepth(1)
                            } else
                            {
                                if (v > 10)
                                {
                                    setAuthZSelfRelDepth(10)
                                }
                            }
                        }}
                        onChange={(e) => setAuthZSelfRelDepth(Number(e.target.value))}
                    />

                    <SelectFieldStyled
                        value={authZAllowByDefault}
                        label="Default Authorization Decision"
                        onChange={(e) => { onChangeSwitch(e.target.value as any) }}>
                        <MenuItem value={false as any}>
                            {t("dataserver:ModalDataServer.auth.deny")}
                        </MenuItem>

                        <MenuItem value={true as any}>
                            {t("dataserver:ModalDataServer.auth.allow")}
                        </MenuItem>
                    </SelectFieldStyled>

                    <SelectFieldStyled
                        value={authZIsMostPermissive}
                        label={t("dataserver:ModalDataServer.auth.Permissiveness")}
                        onChange={(e) => { onChangePermissive(e.target.value as any) }}>
                        <MenuItem value={true as any}>
                            {t("dataserver:ModalDataServer.auth.mostPermissive")}
                        </MenuItem>

                        <MenuItem value={false as any}>
                            {t("dataserver:ModalDataServer.auth.leastPermissive")}
                        </MenuItem>
                    </SelectFieldStyled>
                    { !authZIsMostPermissive &&
                            <TextField
                            label={t("dataserver:ModalDataServer.auth.permissionOrdering")}
                            value={actionPermissionOrder}
                            onChange={(e) => {
                                setActionPermissionOrder(e.target.value);
                            }}
                        />
                    }


                </>
            )}

            {selectedTab === 3 && (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div>
                            <SelectFieldStyled
                                data-cy="modalAPIServerPage_dbType"
                                style={{ width: 310, margin: 5, marginLeft: 0 }}
                                value={dbType}
                                label={t("dataserver:ModalDataServer.dbType.label")}
                                onChange={(e) => {
                                    setDBType(e.target.value);

                                    const currentType = dbTypesAvailables.find(t => t.context === e.target.value)
                                    setDbVersionAvailables(currentType.versions)
                                }}>
                                    {dbTypesAvailables.map(({ context, title }) => (
                                        <MenuItem key={context} value={context} data-cy={`dbType-${context}`}>
                                            {title}
                                        </MenuItem>
                                    ))}
                            </SelectFieldStyled>

                        </div>
                        <div>
                            <SelectFieldStyled
                                data-cy="modalAPIServerPage_dbVersion"
                                style={{ width: 300, margin: 5, marginRight: 0 }}
                                value={dbVersion}
                                label={t("dataserver:ModalDataServer.dbVersion.label")}
                                placeholder="test"
                                onChange={(e) => {
                                    setDBVersion(e.target.value)
                                }}>
                                    { dbVersionAvailables.length === 0 && <MenuItem disabled>First, select a DB Type</MenuItem> }

                                    {dbVersionAvailables.map(({ context, title }) => (
                                        <MenuItem key={context} value={context} data-cy={`dbVersion-${context}`}>
                                            {title}
                                        </MenuItem>
                                    ))}
                            </SelectFieldStyled>
                        </div>
                    </div>

                    <TextField
                            data-cy="modalAPIServerPage_dbHost"
                            label={t("dataserver:ModalDataServer.dbHost.label")}
                            value={dbHost}
                            onChange={(e) => {
                                setDBHost(e.target.value);
                            }}
                        />

                    <TextField
                            data-cy="modalAPIServerPage_dbName"
                            label={t("dataserver:ModalDataServer.dbName.label")}
                            value={dbName}
                            onChange={(e) => {
                                setDBName(e.target.value);
                            }}
                        />

                    <TextField
                            style={{ marginBottom: 20 }}
                            data-cy="modalAPIServerPage_dbUser"
                            label={t("dataserver:ModalDataServer.dbUser.label")}
                            value={dbUser}
                            onChange={(e) => {
                                setDBUser(e.target.value);
                            }}
                        />

                    <PwdField
                            data-cy="modalAPIServerPage_dbPass"
                            label={t("dataserver:ModalDataServer.dbPass.label")}
                            value={dbPass}
                            onChange={(e) => {
                                setDBPass(e.target.value);
                            }}
                    />

                    <UsersContainer disabled={loadingDatabaseConnection || isEmpty(dbType) || isEmpty(dbVersion) || isEmpty(dbHost) || isEmpty(dbName) || isEmpty(dbUser) || isEmpty(dbPass)} onClick={() => testConnection(true)} style={{ marginBottom: 10 }}>
                        {t("dataserver:ModalDataServer.test-database")}
                    </UsersContainer>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                            {t("dataserver:ModalDataServer.load-from-database-title")}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component="span">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <SwitchBoxStyled style={{ padding: 0, width: 'fit-content' }}>
                                        <SwitchButton
                                            checked={nodeProps}
                                            onChange={(event) => {
                                                setNodeProps(event.target.checked);
                                            }}
                                        />

                                        <SwitchLabelStyled style={{ cursor: 'pointer' }} onClick={() => { setNodeProps(!nodeProps) }}>
                                            <b>
                                            {t("dataserver:ModalDataServer.load-from-database-node-props")}
                                            </b>
                                        </SwitchLabelStyled>
                                    </SwitchBoxStyled>

                                    <SwitchBoxStyled style={{ padding: 0, width: 'fit-content' }}>
                                        <SwitchButton
                                            checked={relProps}
                                            onChange={(event) => {
                                                setRelProps(event.target.checked);
                                            }}
                                        />

                                        <SwitchLabelStyled style={{ cursor: 'pointer' }} onClick={() => { setRelProps(!relProps) }}>
                                            <b>
                                            {t("dataserver:ModalDataServer.load-from-database-rel-props")}
                                            </b>
                                        </SwitchLabelStyled>
                                    </SwitchBoxStyled>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
                                        <UsersContainer onClick={() => deleteCurrentSchema()} style={{ width: 200 }}>
                                            {t("dataserver:ModalDataServer.delete-schema-database")}
                                        </UsersContainer>

                                        <UsersContainer disabled={loadingDatabaseConnection || loadSchemaDBLoading || isEmpty(dbType) || isEmpty(dbVersion) || isEmpty(dbHost) || isEmpty(dbName) || isEmpty(dbUser) || isEmpty(dbPass)} onClick={() => startLoadingSchemaDB()} style={{ width: 150 }}>
                                            { loadSchemaDBLoading ? "Loading..." : t("dataserver:ModalDataServer.load-from-database")}
                                        </UsersContainer>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                            {t("dataserver:ModalDataServer.load-from-VC-title")}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component="span">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    <TextAreaStyled
                                        label={t("dataserver:ModalDataServer.VC.inputLabel")}
                                        style={{ width: '100%' }}
                                        value={VPString}
                                        onChange={(e) => {
                                            setVPString(e.target.value);
                                        }}
                                    />

                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
                                        <UsersContainer disabled={loadingDatabaseConnection || loadingSchemaVPLoading || isEmpty(dbType) || isEmpty(dbVersion) || isEmpty(dbHost) || isEmpty(dbName) || isEmpty(dbUser) || isEmpty(dbPass)|| isEmpty(VPString)} onClick={() => importSchemaFromVerifiablePresentation()} style={{ width: 150 }}>
                                            { loadingSchemaVPLoading ? "Loading..." : t("dataserver:ModalDataServer.load-from-VC-btn")}
                                        </UsersContainer>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                {t("dataserver:ModalDataServer.extra-connection-title")}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography component="div">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t("dataserver:ModalDataServer.NEO4J_POOL_SIZE.label")}
                                        inputProps={{ type: 'number', min: 1 }}
                                        value={NEO4J_POOL_SIZE}
                                        onChange={(e) => {
                                            setNEO4J_POOL_SIZE(e.target.value);
                                        }}
                                    />

                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t("dataserver:ModalDataServer.NEO4J_MAX_CONNECTION_LIFETIME.label")}
                                        inputProps={{ type: 'number', min: 1 }}
                                        value={NEO4J_MAX_CONNECTION_LIFETIME}
                                        onChange={(e) => {
                                            setNEO4J_MAX_CONNECTION_LIFETIME(e.target.value);
                                        }}
                                    />

                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t("dataserver:ModalDataServer.NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS.label")}
                                        inputProps={{ type: 'number', min: 1 }}
                                        value={NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS}
                                        onChange={(e) => {
                                            setNEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS(e.target.value);
                                        }}
                                    />

                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t("dataserver:ModalDataServer.NEO4J_CONNECTION_TIMEOUT.label")}
                                        inputProps={{ type: 'number', min: 1 }}
                                        value={NEO4J_CONNECTION_TIMEOUT}
                                        onChange={(e) => {
                                            setNEO4J_CONNECTION_TIMEOUT(e.target.value);
                                        }}
                                    />
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}

            {selectedTab === 4 && editDataServer && (
                <>
                    <ItemFlexContainer>
                        <RowContainer>
                            <StatusLightBulb $isActive={isProxyRunning} />

                            <URLBox>
                                {isProxyRunning ?
                                    <Link target="_blank" to={{ pathname: `${editDataServer?.connectionURL}/graphql` }}>
                                        API Service
                                    </Link>
                                    : "API Service"}
                            </URLBox>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.address")}
                            </InfoCell>

                            <ValueCell>
                                {`${editDataServer?.connectionURL}/graphql`}
                            </ValueCell>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.status")}
                            </InfoCell>

                            <ValueCell data-cy={`serverUpdatePage_item_status-${editDataServer.name}`}>
                                {isProxyRunning
                                        ? t("serverInfo:modal.tabs.update.server.status.online")
                                        : t("serverInfo:modal.tabs.update.server.status.offline")
                                }
                            </ValueCell>
                        </RowContainer>

                        <RowContainer>
                            <StatusLightBulb $isActive={isAuthzRunning} />

                            <URLBox>
                                { isAuthzRunning ?
                                    <Link target="_blank" to={{ pathname: editDataServer?.authZServer?.connectionURL }}>
                                        Authorization Service
                                    </Link>
                                : "Authorization Service" }
                            </URLBox>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.address")}
                            </InfoCell>

                            <ValueCell>
                                {editDataServer?.authZServer?.connectionURL}
                            </ValueCell>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.status")}
                            </InfoCell>

                            <ValueCell>
                                {isAuthzRunning
                                    ? t("serverInfo:modal.tabs.update.server.status.online")
                                    : t("serverInfo:modal.tabs.update.server.status.offline")
                                }
                            </ValueCell>
                        </RowContainer>
                    </ItemFlexContainer>

                    <ItemFlexContainer>
                        <RowContainer>
                            <StatusLightBulb $isActive={isIdpRunning} />

                            <URLBox>
                                {isIdpRunning ?
                                    <Link target="_blank" to={{ pathname: editDataServer.EXTERNAL_PROVIDER ? editDataServer.EXTERNAL_OIDC_URL : editDataServer.OIDC_URL }}>
                                        OIDC Service
                                    </Link>
                                : "OIDC Service"}
                            </URLBox>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.address")}
                            </InfoCell>

                            <ValueCell>
                                {editDataServer.EXTERNAL_PROVIDER ? editDataServer.EXTERNAL_OIDC_URL : editDataServer.OIDC_URL}
                            </ValueCell>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.status")}
                            </InfoCell>

                            <ValueCell>
                                {isIdpRunning
                                        ? t("serverInfo:modal.tabs.update.server.status.online")
                                        : t("serverInfo:modal.tabs.update.server.status.offline")
                                }
                            </ValueCell>
                        </RowContainer>
                    </ItemFlexContainer>

                    <ItemFlexContainer>
                        <RowContainer>
                            <StatusLightBulb $isActive={isDashboardRunning} />

                            <URLBox>
                                {isDashboardRunning ?
                                    <Link target="_blank" to={{ pathname: editDataServer.dashboardServerURL }}>
                                        Dashboard Service
                                    </Link>
                                : "Dashboard Service"}
                            </URLBox>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.address")}
                            </InfoCell>

                            <ValueCell>
                                {editDataServer.dashboardServerURL}
                            </ValueCell>
                        </RowContainer>

                        <RowContainer>
                            <InfoCell>
                                {t("serverInfo:modal.tabs.update.server.status")}
                            </InfoCell>

                            <ValueCell>
                                {isDashboardRunning
                                        ? t("serverInfo:modal.tabs.update.server.status.online")
                                        : t("serverInfo:modal.tabs.update.server.status.offline")
                                }
                            </ValueCell>
                        </RowContainer>
                    </ItemFlexContainer>
                </>
            )}

            <ButtonListFlex $justifyContent={editDataServer ? "space-between" : null}>
                    {editDataServer &&
                        <>
                            <UsersContainer disabled={isModalCredentialsInfoOpen.isOpen} onClick={() => openCredentials()}>
                                {t("panel:header.btn.apiserver.info")}
                            </UsersContainer>

                            <ModalCredentialsInfo
                                OIDC_PROVIDER={OIDC_PROVIDER}
                                OIDC_URL={editDataServer.OIDC_URL}
                                isConnectionValid={isModalCredentialsInfoOpen.dbValid}
                                serverID={editDataServer?._id}
                                isModalOpen={isModalCredentialsInfoOpen.isOpen}
                                randomName={randomName}
                                setIsModalOpen={setIsModalCredentialsInfoOpen}
                            />
                        </>
                    }

                    {selectedTab < 4 ? (
                        <>
                            {(showSaveButton || isCurrentAdmin || !tenantID) && (
                                <ConfirmButton onClick={onConfirm} disabled={loadingApis || hasError || saving || !CLUSTER_SELECTED || isMissingServiceAccount || isEmpty(name) || (autoDeployApiServer && isEmpty(connectionURL))}>
                                    {saving ?
                                        <ProcessingApiServerIconStyled $insideBtn title={t("dataserver:ModalDataServer.processing")} />
                                        : <div>Save</div>
                                    }
                                </ConfirmButton>
                            )}
                        </>
                    ) : (
                        <ConfirmButton
                            disabled={saving || loadingSchema || !isProxyRunning || !isAuthzRunning || !isIdpRunning || isMissingDBCredentials || isMissingServiceAccount}
                            onClick={async () => {
                                const toastID = toastLoading()

                                void updateSchema({
                                    variables: {
                                        serverID: editDataServer._id
                                    }
                                }).then(async ({ data, errors }: any) => {
                                    if (errors) {
                                        for (const e of errors) {
                                            if (e.message.length > 200) {
                                                void Swal.fire({
                                                    icon: "error",
                                                    title: "Error",
                                                    text: e.message
                                                });
                                            } else {

                                                const errMsg = e.message.indexOf("{{");
                                                if (errMsg > -1) {
                                                    const msg = e.message.slice(0, Math.max(0, errMsg)).trim();
                                                    const eMsg = e.message.slice(errMsg).replace("{{", "").replace("}}", "");

                                                    toastUpdate(toastID, t(`validations:${msg}`) + " " + `${eMsg}`, "error")
                                                    return;
                                                }

                                                toastUpdate(toastID, t(`validations:${e.message}`), "error")
                                            }
                                        }

                                        return;
                                    }

                                    const { status } = data.updateDataServerSchema;

                                    if (status)
                                    {
                                        setLastUpdate(data.updateDataServerSchema.lastUpdate ? formatDate(data.updateDataServerSchema.lastUpdate) : t("serverInfo:modal.tabs.update.server.last.unknown"));
                                        toastUpdate(toastID, t("serverInfo:modal.tabs.update.server.updated.success"), "success")

                                        if (CLUSTER_SELECTED !== Cluster.LOCALHOST)
                                        {
                                            await sleepFor(1)
                                            toastInfo(t("serverInfo:modal.tabs.update.server.updated.reflect"));
                                        }
                                    } else {
                                        toastUpdate(toastID, t("serverInfo:modal.tabs.update.server.updated.failed"), "error")
                                    }

                                    if (status) {
                                        refetchService()
                                    }
                                });
                            }}
                        >
                            { loadingSchema ? "Updating..." : t("serverInfo:modal.tabs.update.server.all")}
                        </ConfirmButton>
                    )}
            </ButtonListFlex>
        </Modal>
    );
}

export default ModalDS;
