/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable object-property-newline */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-newline */
/* eslint-disable prefer-const */
/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable no-empty */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { toastError, toastLoading, toastUpdate } from "@3edges/utils/dist/toastify";
import { isEmpty, isWebLocalhost } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { getStorage, selectedUser } from "cache";
import { UPDATE_TO_RELEASED_SPECIFIC_API } from "components/PanelDeploymentManagement/gql";
import { ALL_STATUS, UPDATE_STARTSTOP } from "components/PanelServer/gql";
import { useData } from "contexts/dataContext";
import { LoadingData } from "pages/ServiceAccounts/styled";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ClusterEnum, GetAllTenants_niamTenants, Role, GetAllTenants_niamTenants_administrators as User, podEnum } from "types/operation-result-types";
import
    {
        DelDataServerIconStyled,
        EditDataServerIconStyled,
        PlayApiServerIconStyled,
        ProcessingApiServerIconStyled,
        ShareDataServerIconStyled,
        StopApiServerIconStyled,
        TenantUsersIconStyled,
        TextBox,
        UpdateDataServerIconStyled
    } from "ui-components/PanelCard/styled";
import { isAdminOnThisDataserver, isOwner } from "utils";
import { Cluster, isSuperAdmin } from "utilsFn";
import { TableCellStyled } from "./styled";

interface ListPanelData {
    name: string;
    administrators?: {
        email?: string;
        roles: Role[];
    }[];
    __typename: string;
    _id: string;
}
interface ListPanelProps {
    data: ListPanelData[];
    isAdd?: boolean;
    loading?: boolean;
    parentPage: "dataserver" | "tenant";
    onMessageStatus?: (params: any) => void;
    onEdit: (data: ListPanelData) => void;
    onShare?: (data: ListPanelData) => void;
    onShowUsers?: (data: ListPanelData) => void;
    onDelete: (data: ListPanelData) => void;
    onSelect: (id: string) => void;
    children?: React.ReactNode;
    t?: any;
}

function ListPanelItem (ListPanelParams, row: any): React.ReactElement {
    const { t } = useTranslation();
    const { getTenant } = useData();
    const currUser = getStorage<any>("user");
    let isCurrentAdmin = isAdminOnThisDataserver(row?.administrators, currUser)

    const history = useHistory();
    const superAdmin = isSuperAdmin();
    const [btnDeleteDisabled, setBtnDeleteDisabled] = useState(true);
    const [updateNeeded, setUpdateNeeded] = useState(false);

    const [podStatus, setPodStatus] = useState<podEnum>(podEnum.PROCESSING)
    const [clusterStatus, setClusterStatus] = useState<ClusterEnum>(ClusterEnum.PROCESSING)

    const { isAdd, onMessageStatus, onEdit, onDelete, onSelect, onShare, onShowUsers, parentPage }: ListPanelProps = ListPanelParams

    const [getAllStatus, { data: dataAllStatus }] = useLazyQuery(ALL_STATUS, { fetchPolicy: "no-cache" });
    const [updateStartStop, { data: dataUpdateStartStop }] = useMutation(UPDATE_STARTSTOP);

    const [updateToReleasedSpecificWorkload] = useMutation(UPDATE_TO_RELEASED_SPECIFIC_API);

    useEffect(() =>
    {
        let isMounted = true;
        async function fetchStatus()
        {
            if (row && parentPage !== "tenant")
            {
                try
                {
                    if (isMounted)
                    {
                        getAllStatus({ variables: { serverID: row._id } })
                    }
                } catch {}
            }
        }

        fetchStatus();

        return () =>
        {
            isMounted = false
        };
    }, [row]);

    useEffect(() => {
        if (row && getAllStatus && dataUpdateStartStop && parentPage !== "tenant" && row["CLUSTER_SELECTED"] !== Cluster.LOCALHOST) {
            setPodStatus(dataUpdateStartStop.updateStartStop.status)
            onMessageStatus(dataUpdateStartStop.updateStartStop.status)
        }
    }, [row, dataUpdateStartStop])

    useEffect(() => {
        if (dataAllStatus)
        {
            const { getAllStatus } = dataAllStatus

            if (getAllStatus.isProcessing) {
                setPodStatus(podEnum.PROCESSING)
            } else {
                setPodStatus(getAllStatus.podStatus)
            }
            setClusterStatus(getAllStatus.clusterStatus)

            if (getAllStatus.podStatus === podEnum.PROCESSING || getAllStatus.clusterStatus === ClusterEnum.PROCESSING) {
                if (getAllStatus.clusterStatus === ClusterEnum.DISCONNECTED) {
                    setBtnDeleteDisabled(false)
                } else {
                    setBtnDeleteDisabled(row["CLUSTER_SELECTED"] !== Cluster.LOCALHOST ? true : false)
                }
            } else {
                setBtnDeleteDisabled(false)
            }

            let newUpdateNeeded = (new Date(getAllStatus.lastReleaseDate) <= new Date(getAllStatus.niamLastReleaseDate)) || isEmpty(getAllStatus.lastReleaseDate) ? true : false;
            if (getAllStatus.isProcessing) {
                newUpdateNeeded = false
            }
            setUpdateNeeded(newUpdateNeeded)
        }
    }, [dataAllStatus])

    const showOwner = (admins: ListPanelData): string => {
        const collectionAdmins: User[] = [];

        if (admins.administrators) {
            admins.administrators.forEach((f) => {

                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                if (f.roles && f.roles?.includes(Role.SUPER_ADMIN)) {
                    return;
                }

                collectionAdmins.push(f as User);
            });

            if (collectionAdmins) {
                const sameUser = collectionAdmins.find((f) => f.email === selectedUser().email);

                if (collectionAdmins.length > 1) {
                    return `${collectionAdmins.length} ${t("panel:number_of_admins")}`;
                }

                if (!sameUser && collectionAdmins.length > 0) {
                    return collectionAdmins[0].email;
                }

                return `${t("panel:owner.yours")}`;
            }
        }

        return `${t("panel:owner.yours")}`;
    };

    const onStartStopApiServer = (serverID, start) => {
        setPodStatus(podEnum.PROCESSING)
        updateStartStop({ variables: { serverID, start } })
    }

    const onDeleteEvent = (row: any) => {
        if (btnDeleteDisabled && parentPage !== "tenant") {
            toastError(t("serverInfo:apiServer.cannot.delete"));
        } else {
            onDelete(row)
        }
    }

    const showUsers = (tenant: GetAllTenants_niamTenants): void => {
        getTenant(tenant);
        history.push(`/${tenant._id}/admin-page`);
    };

    // You are on Dataserver page
    if (parentPage !== "tenant") {
        isCurrentAdmin = true;
    }

    function TextBoxApiStatus(props: any)
    {
        const { color, children } = props

        return (
            <div style={{
                textAlign: 'left',
                color: color,
                fontSize: 12,
                display: 'flex'
            }}>
                <div style={{ marginRight: 5, height: 15, width: 15, backgroundColor: color, borderRadius: "50%" }} /> {children}
            </div>
        )
    }

    const redeploySpecificPod = async (clientNameGCP) =>
    {
        setUpdateNeeded(false)
        const toastID = toastLoading()

        const { data, errors }: any = await updateToReleasedSpecificWorkload({
            variables: {
                local: isWebLocalhost() ? Cluster.LOCALHOST : Cluster.THREEEDGES,
                clientNameGCP
            }
        })

        if (errors) {
            for (const e of errors) {
                toastUpdate(toastID, t(`validations:${e.message}`), "error")
            }

            setUpdateNeeded(true)
            return;
        }

        if (data.updateToReleasedSpecificWorkload.status)
        {
            setPodStatus(podEnum.PROCESSING)
            setUpdateNeeded(false)
            toastUpdate(toastID, t("validations:success.done"), "success")
        }
    }

    return (
        <TableRow hover style={{ cursor: "pointer" }}>
            <TableCell
                component="th"
                scope="row"
                onClick={() => {
                    onSelect(row._id);
                }}
            >
                {row.name}
            </TableCell>

            <TableCell
                    onClick={() => {
                        onSelect(row._id);
                    }}
                >
                { row.administrators && showOwner(row) }
            </TableCell>

            {parentPage !== "tenant" && (
                <TableCell onClick={() => { onSelect(row._id) }}>
                    <TextBoxApiStatus color={podStatus === podEnum.RUNNING ? "#007700" : podStatus === podEnum.STOPPED || clusterStatus === ClusterEnum.DISCONNECTED ? "#dd0000" : "orange"}>
                        {clusterStatus === ClusterEnum.DISCONNECTED ? clusterStatus : podStatus}
                    </TextBoxApiStatus>

                    {!isWebLocalhost() && updateNeeded && (isCurrentAdmin || superAdmin) &&
                        <TextBox fontSize="12px" style={{ color: "red", fontWeight: "bold" }}>{t("panel:apiServer.update.needed")}</TextBox>
                    }
                </TableCell>
            )}

            <TableCellStyled align="right">
                <div className="actions">
                    {row && row["CLUSTER_SELECTED"] !== Cluster.LOCALHOST && clusterStatus === ClusterEnum.CONNECTED && row["autoDeployApiServer"] && (
                        <>
                            {(isEmpty(podStatus) || podStatus === podEnum.STOPPED) && !isAdd && parentPage !== "tenant" && (
                                <PlayApiServerIconStyled $smallCard>
                                    <span onClick={() => { onStartStopApiServer(row._id, true) }} title={t("dataserver:ModalDataServer.startBtn")} className="material-icons-outlined">play_circle</span>
                                </PlayApiServerIconStyled>
                            )}

                            {podStatus === podEnum.PROCESSING && !isAdd && parentPage !== "tenant" &&
                                <ProcessingApiServerIconStyled $smallCard title={t("dataserver:ModalDataServer.processing")} />
                            }

                            {podStatus === podEnum.RUNNING && !isAdd && parentPage !== "tenant" && (
                                <StopApiServerIconStyled $smallCard>
                                    <span onClick={() => onStartStopApiServer(row._id, false)} title={t("dataserver:ModalDataServer.stopBtn")} className="material-icons-outlined">stop_circle</span>
                                </StopApiServerIconStyled>
                            )}
                        </>
                    )}

                    {!isWebLocalhost() && updateNeeded && parentPage !== "tenant" && (isCurrentAdmin || superAdmin) &&
                        <UpdateDataServerIconStyled $smallCard>
                            <span onClick={() => redeploySpecificPod(row?.["clientNameGCP"])} title={t("dataserver:ModalDataServer.updateBtn")} className="material-icons-outlined blink_me">manage_history</span>
                        </UpdateDataServerIconStyled>
                    }

                    {onShare && (isCurrentAdmin || superAdmin) && (isOwner(row, currUser) || superAdmin) && (
                        <ShareDataServerIconStyled $smallCard>
                            <span onClick={() => { onShare(row) }} title={t("dataserver:ModalDataServer.shareBtn")} className="material-icons">group_add</span>
                        </ShareDataServerIconStyled>
                    )}

                    {onShowUsers && (isCurrentAdmin || superAdmin) && parentPage === "tenant" && onShowUsers && (
                        <TenantUsersIconStyled $smallCard>
                            <span onClick={() => { showUsers(row) }} title={t("dataserver:ModalDataServer.addPersonBtn")} className="material-icons">person_add</span>
                        </TenantUsersIconStyled>
                    )}

                    {onEdit && (isCurrentAdmin || superAdmin) && (
                        <EditDataServerIconStyled $smallCard>
                            <span onClick={() => { onEdit(row) }} title={t("dataserver:ModalDataServer.editBtn")} className="material-icons">edit</span>
                        </EditDataServerIconStyled>
                    )}

                    {onDelete && (isCurrentAdmin || superAdmin) && (
                        <DelDataServerIconStyled $smallCard>
                            <span onClick={() => { onDeleteEvent(row) }} title={t("dataserver:ModalDataServer.deleteBtn")} className="material-icons">delete</span>
                        </DelDataServerIconStyled>
                    )}
                </div>
            </TableCellStyled>
        </TableRow>
    );
}

function ListPanel (ListPanelParams: ListPanelProps): React.ReactElement {
    const { data, loading, children, parentPage, t } = ListPanelParams

    return (
        <>
            {children}

            {loading && (
                <LoadingData>
                    <div>Loading data</div>
                    <div className="loader" />
                </LoadingData>
            )}

            {!loading && data.length > 0 && (
                <TableContainer
                    component={Paper}
                    style={{
                        marginTop: 15,
                        position: "relative",
                        zIndex: 1
                    }}
                >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#EEE" }}>
                                <TableCellStyled
                                    style={{
                                        width: "45%"
                                    }}
                                >
                                    {t("panel:list.table.title")}
                                </TableCellStyled>

                                <TableCellStyled
                                        style={{
                                            width: "45%"
                                        }}
                                    >
                                    {t("panel:list.table.owner")}
                                </TableCellStyled>

                                {parentPage !== "tenant" && (
                                    <TableCellStyled
                                        align="left"
                                        style={{
                                            width: "45%"
                                        }}
                                    >
                                        {t("panel:list.table.status")}
                                    </TableCellStyled>
                                )}

                                <TableCellStyled
                                    align="right"
                                    style={{
                                        width: "10%"
                                    }}
                                >
                                    {t("panel:list.table.actions")}
                                </TableCellStyled>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.map((row: any) => <Fragment key={row._id}>{ListPanelItem(ListPanelParams, row)}</Fragment> )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}

export default ListPanel;
