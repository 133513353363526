/* eslint-disable max-len */
import { gql } from "@apollo/client";

export const ADD_NEW_PRIM_DATA_SERVER = gql`
  mutation addNewNiamDataServer($name: String!, $description: String, $serviceAccountID: ID!, $clusterID: ID, $AUTHN_QUERY: String, $CLAIMS_ARRAY: String, $connectionURL: String, $randomName: String!, $CLUSTER_SELECTED: String!, $clusterAutopilot: Boolean, $clusterLocation: String, $autoDeployApiServer: Boolean, $dbParams: DBParams!, $authzParams: AuthzParams!, $authzCSPConnectionURL: String, $oidcParams: OIDCParams!, $dashboardParams: DashboardParams!, $lastReleaseDate: String) {
    addNewNiamDataServer(input: { name: $name, description: $description, serviceAccountID: $serviceAccountID, clusterID: $clusterID, AUTHN_QUERY: $AUTHN_QUERY, CLAIMS_ARRAY: $CLAIMS_ARRAY, connectionURL: $connectionURL, randomName: $randomName, CLUSTER_SELECTED: $CLUSTER_SELECTED, clusterAutopilot: $clusterAutopilot, autoDeployApiServer: $autoDeployApiServer, clusterLocation: $clusterLocation, dbParams: $dbParams, authzParams: $authzParams, authzCSPConnectionURL: $authzCSPConnectionURL, oidcParams: $oidcParams, dashboardParams: $dashboardParams, lastReleaseDate: $lastReleaseDate }) {
      _id
      name
      description
      connectionURL
      clientNameGCP
      CLUSTER_SELECTED
      clusterAutopilot
      clusterLocation
      cluster {
        _id
        clusterID
        name
        location
      }
      serviceAccount {
        _id
        cloud
      }
      autoDeployApiServer
      dbType
      dbVersion
      dbHost
      dbName
      dbUser
      dbPass
      lastUpdate
      NEO4J_POOL_SIZE
      NEO4J_MAX_CONNECTION_LIFETIME
      NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS
      NEO4J_CONNECTION_TIMEOUT
      EXTERNAL_PROVIDER
      OIDC_URL
      CLAIMS_ARRAY
      AUTHN_QUERY
      EXTERNAL_OIDC_URL
      OIDC_TOKEN_ENDPOINT
      OIDC_TOKEN_INTROSPECTION_ENDPOINT
      ACCESS_TOKEN_TYPE
      OIDC_JWKS_URI
      EXTERNAL_PROVIDER_NAME
      OIDC_ME_ENDPOINT
      OIDC_AUTHORIZE_ENDPOINT
      OIDC_LOGOUT_ENDPOINT
      OIDC_PROVIDER_CLAIMS_MAPPING
      OIDC_CLIENT_ID
      OIDC_CLIENT_PWD
      authZServer {
        _id
        connectionURL
        lastUpdate
        defaultOperator
        allowByDefault
        selfRelDepth
        isMostPermissive
        actionPermissionOrder
      }
      administrators {
          roles
          email
      }
      owners {
        _id
        email
      }
      authzCSPConnectionURL
      dashboardServerURL
      lastReleaseDate
    }
  }
`;

export const ADD_NEW_PRIM_TENANT_DATA_SERVER = gql`
  mutation addNewNiamTenantDataServer($name: String!, $description: String, $serviceAccountID: ID!, $clusterID: ID, $AUTHN_QUERY: String, $CLAIMS_ARRAY: String, $connectionURL: String, $randomName: String!, $CLUSTER_SELECTED: String!, $clusterAutopilot: Boolean, $clusterLocation: String, $autoDeployApiServer: Boolean, $dbParams: DBParams!, $authzParams: AuthzParams!, $authzCSPConnectionURL: String, $oidcParams: OIDCParams!, $dashboardParams: DashboardParams!, $tenantID: ID!, $lastReleaseDate: String) {
    addNewNiamTenantDataServer(input: { name: $name, description: $description, serviceAccountID: $serviceAccountID, clusterID: $clusterID, AUTHN_QUERY: $AUTHN_QUERY, CLAIMS_ARRAY: $CLAIMS_ARRAY, connectionURL: $connectionURL, randomName: $randomName, CLUSTER_SELECTED: $CLUSTER_SELECTED, clusterAutopilot: $clusterAutopilot, clusterLocation: $clusterLocation, autoDeployApiServer: $autoDeployApiServer, authzCSPConnectionURL: $authzCSPConnectionURL, dbParams: $dbParams, authzParams: $authzParams, oidcParams: $oidcParams, dashboardParams: $dashboardParams, tenantID: $tenantID, lastReleaseDate: $lastReleaseDate }) {
      _id
      name
      description
      connectionURL
      clientNameGCP
      CLUSTER_SELECTED
      clusterAutopilot
      clusterLocation
      serviceAccount {
        _id
        cloud
      }
      cluster {
        _id
        clusterID
        name
        location
      }
      autoDeployApiServer
      dbType
      dbVersion
      dbHost
      dbName
      dbUser
      dbPass
      lastUpdate
      NEO4J_POOL_SIZE
      NEO4J_MAX_CONNECTION_LIFETIME
      NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS
      NEO4J_CONNECTION_TIMEOUT
      EXTERNAL_PROVIDER
      OIDC_URL
      CLAIMS_ARRAY
      AUTHN_QUERY
      EXTERNAL_OIDC_URL
      OIDC_TOKEN_ENDPOINT
      OIDC_TOKEN_INTROSPECTION_ENDPOINT
      ACCESS_TOKEN_TYPE
      OIDC_JWKS_URI
      EXTERNAL_PROVIDER_NAME
      OIDC_ME_ENDPOINT
      OIDC_AUTHORIZE_ENDPOINT
      OIDC_LOGOUT_ENDPOINT
      OIDC_PROVIDER_CLAIMS_MAPPING
      OIDC_CLIENT_ID
      OIDC_CLIENT_PWD
      authZServer {
        _id
        connectionURL
        lastUpdate
        defaultOperator
        allowByDefault
        selfRelDepth
        isMostPermissive
        actionPermissionOrder
      }
      administrators {
          roles
          email
      }
      owners {
        _id
        email
      }
      authzCSPConnectionURL
      dashboardServerURL
      lastReleaseDate
    }
  }
`

export const DELETE_SCHEMA_DB = gql`
  mutation deleteSchemaDB($serverID: ID!) {
    deleteSchemaDB(input: { serverID: $serverID })
  }
`

export const UPDATE_PRIM_DATA_SERVER = gql`
  mutation updateNiamDataServer($removeOldCluster: Boolean, $serverID: ID!, $name: String!, $description: String, $serviceAccountID: ID!, $clusterID: ID, $authzCSPConnectionURL: String, $AUTHN_QUERY: String, $CLAIMS_ARRAY: String, $connectionURL: String, $randomName: String!, $CLUSTER_SELECTED: String!, $clusterAutopilot: Boolean, $clusterLocation: String, $tenantID: ID, $dbParams: DBParams!, $authzParams: AuthzParams!, $oidcParams: OIDCParams!, $dashboardParams: DashboardParams!, $MAX_TROTTLE_COST: String) {
    updateNiamDataServer(input: { serverID: $serverID, removeOldCluster: $removeOldCluster, name: $name, description: $description, serviceAccountID: $serviceAccountID, clusterID: $clusterID, authzCSPConnectionURL: $authzCSPConnectionURL, AUTHN_QUERY: $AUTHN_QUERY, CLAIMS_ARRAY: $CLAIMS_ARRAY, connectionURL: $connectionURL, randomName: $randomName, CLUSTER_SELECTED: $CLUSTER_SELECTED, clusterAutopilot: $clusterAutopilot, clusterLocation: $clusterLocation, tenantID: $tenantID, dbParams: $dbParams, authzParams: $authzParams, oidcParams: $oidcParams, dashboardParams: $dashboardParams, MAX_TROTTLE_COST: $MAX_TROTTLE_COST }) {
      _id
      name
      description
      connectionURL
      clientNameGCP
      CLUSTER_SELECTED
      clusterAutopilot
      clusterLocation
      serviceAccount {
        _id
        cloud
      }
      cluster {
        _id
        clusterID
        name
        location
      }
      autoDeployApiServer
      dbType
      dbVersion
      dbHost
      dbName
      dbUser
      dbPass
      lastUpdate
      MAX_TROTTLE_COST
      NEO4J_POOL_SIZE
      NEO4J_MAX_CONNECTION_LIFETIME
      NEO4J_CONNECTION_ACQUISITION_TIMEOUT_MS
      NEO4J_CONNECTION_TIMEOUT
      EXTERNAL_PROVIDER
      OIDC_URL
      CLAIMS_ARRAY
      AUTHN_QUERY
      EXTERNAL_OIDC_URL
      OIDC_TOKEN_ENDPOINT
      OIDC_TOKEN_INTROSPECTION_ENDPOINT
      ACCESS_TOKEN_TYPE
      OIDC_JWKS_URI
      EXTERNAL_PROVIDER_NAME
      OIDC_ME_ENDPOINT
      OIDC_AUTHORIZE_ENDPOINT
      OIDC_LOGOUT_ENDPOINT
      OIDC_PROVIDER_CLAIMS_MAPPING
      OIDC_CLIENT_ID
      OIDC_CLIENT_PWD
      authZServer {
        _id
        connectionURL
        lastUpdate
        defaultOperator
        allowByDefault
        selfRelDepth
        isMostPermissive
        actionPermissionOrder
      }
      administrators {
          roles
          email
      }
      owners {
        _id
        email
      }
      authzCSPConnectionURL
      dashboardServerURL
    }
  }
`;

export const CHECK_URL_HEALTH = gql`
  query checkUrlHealth($url: String!) {
    checkUrlHealth(input: { url: $url }) {
      status
    }
  }
`

export const CHECK_CONNECTION_URL = gql`
  query checkConnectionURL($connectionURL: String, $serverID: ID) {
    checkConnectionURL(input: { connectionURL: $connectionURL, serverID: $serverID }) {
      status
      dataservers {
        _id
        name
      }
    }
  }
`

export const CHECK_DATABASE_CONNECTION = gql`
  query checkDatabaseConnection($dbParams: DBParams!) {
    checkDatabaseConnection(input: { dbParams: $dbParams })
  }
`

export const LOAD_SCHEMA_DB = gql`
  query loadSchemaDB($serverID: ID!, $withProperties: Boolean, $withRelProperties: Boolean) {
    loadSchemaDB(input: { serverID: $serverID, withProperties: $withProperties, withRelProperties: $withRelProperties })
  }
`

export const CHECK_DNS = gql`
  query checkDNS($connectionURL: String) {
    checkDNS(input: { connectionURL: $connectionURL }) {
      status
    }
  }
`

export const UPDATE_THE_SCHEMA = gql`
  mutation updateDataServerSchema($serverID: ID!) {
      updateDataServerSchema(input: { serverID: $serverID }) {
        lastUpdate
        status
        connectionURL
      }
  }
`;

export const LOAD_SCHEMA_VP = gql`
  mutation importConfigVC($serverID: ID!, $VP: String!) {
    importConfigVC(input: { serverID: $serverID, VP: $VP })
  }
`
