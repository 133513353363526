/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-component-props */
import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory, useParams } from "react-router-dom";
import { REACT_ENV } from "environmentVariables";
import { FindInPageOutlined, PostAdd, SettingsOutlined, ApartmentOutlined } from "@material-ui/icons";
import PeopleIcon from '@material-ui/icons/People';

const drawerWidth = 210;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export default function SidebarWrapper({ children, open, handleDrawerClose }): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const { tenantID, serverID } = useParams<{ tenantID: string, serverID: string }>();

  const destinations = ['Organizations', 'Standalone Deployments', 'User Management', 'Deployment Management', 'Cloud Service Accounts', 'Data Loader', 'Documentation'];

  const handleRoute = (destination: string): void => {
    switch (destination) {
      case 'Organizations':
        history.push('/');
        break;
      case 'Standalone Deployments':
        if (tenantID || serverID) {
          window.location.replace('/servers');
        } else {
            history.push('/servers');
        }
        break;
      case 'User Management':
        if (tenantID || serverID) {
          window.location.replace('/admin-page');
        } else {
            history.push('/admin-page');
        }
        break;
      case 'Deployment Management':
        history.push('/deployment-management');
        break;
      case 'Cloud Service Accounts':
        history.push('/service-accounts');
        break;
      case 'Data Loader':
        window.open(REACT_ENV.REACT_APP_WEBLOADER_URL, "documentationPopup");
        break;
      case 'Documentation':
        window.open("https://docs.3edges.com/space/3edgesDoc/2211512321/Introduction", "documentationPopup");
        break;
      default:
        history.push('/');
    }
  };

  const switchIcons = (destination: string): React.ReactNode => {
    switch (destination) {
      case 'Organizations':
        return <ApartmentOutlined style={{ margin: "0 16px 0 0" }} />
      case 'Standalone Deployments':
        return <span style={{ margin: "0 16px 0 0" }} className="material-icons">webhook</span>
      case 'User Management':
        return <PeopleIcon style={{ margin: "0 16px 0 0" }} />
      case 'Deployment Management':
        return  <span style={{ margin: "0 16px 0 0" }} className="material-icons-outlined">manage_history</span>
      case 'Cloud Service Accounts':
        return  <span style={{ margin: "0 16px 0 0" }} className="material-icons-outlined">badge</span>
      case 'Data Loader':
        return <PostAdd style={{ margin: "0 16px 0 0" }} />
      case 'Documentation':
        return <FindInPageOutlined style={{ margin: "0 16px 0 0" }} />
      default:
        return <SettingsOutlined style={{ margin: "0 16px 0 0" }} />
    }
  }

  return (
      <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
            paper: classes.drawerPaper,
            }}
           >
              <div className={classes.drawerHeader}>
                  <IconButton onClick={() => handleDrawerClose(false)}>
                      {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
              </div>

              <Divider />

              <List>
                  {destinations.map((text, index) => (
                      <ListItem
                        button
                        key={text}
                        onClick={() => {
                        handleRoute(text);
                      }}>
                          <ListItemIcon> { switchIcons(text) } </ListItemIcon>

                          <ListItemText primary={text} />
                      </ListItem>
                    ))}
              </List>
          </Drawer>

          <main
            className={clsx(classes.content, {
            [classes.contentShift]: open,
            })}
          >
              { children }
          </main>
      </div>
  );
}
